import { makeStyles } from "@material-ui/core";
import qs from "qs";
import * as React from "react";
import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";
import { ClaimTitle } from "../../claim/ClaimTitle";
import AccountingFlagForm from "./steps/AccountingFlagForm";
import BasicInfoForm from "./steps/BasicInfoForm";
import CommentForm from "./steps/CommentForm";
import OperationFlagsForm from "./steps/OperationFlagForm";

interface UserCreateFormProps {
  activeStep: number;
  onValidationChange: (isValid: boolean) => void;
}

const useStyles = makeStyles({
  stepContainer: {
    position: "relative",
    overflow: "hidden",
    padding: "0 3rem",
    minHeight: "400px",
  },
  step: {
    position: "absolute",
    width: "100%",
    transition: "all 0.3s ease-out",
    opacity: 0,
    transform: "translateX(50px)",
  },
  stepActive: {
    opacity: 1,
    transform: "translateX(0)",
    position: "relative",
  },
  stepPrev: {
    opacity: 0,
    transform: "translateX(-50px)",
  },
  stepNext: {
    opacity: 0,
    transform: "translateX(50px)",
  },
});

const UserCreateForm: React.FC<UserCreateFormProps> = ({
  activeStep,
  onValidationChange,
}): JSX.Element => {
  const classes = useStyles();
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();
  let isNormalUser = true;
  let isEmployee = false;

  if (
    parsedLocation.userType &&
    parsedLocation.userType !== "Default" &&
    values &&
    values.userType !== "Default"
  ) {
    isNormalUser = false;
  }

  if (isNormalUser) {
    isEmployee = ["manager", "admin", "lightManager"].some((str) =>
      values.roles?.includes(str)
    );
  }

  const getStepClass = (step: number) => {
    if (step === activeStep) return `${classes.step} ${classes.stepActive}`;
    if (step < activeStep) return `${classes.step} ${classes.stepPrev}`;
    return `${classes.step} ${classes.stepNext}`;
  };

  return (
    <>
      <div className={getStepClass(0)}>
        <BasicInfoForm
          isAdditionalContact={false}
          isInsolvencyAdministrator={false}
          isLaywerUser={false}
          isPartner={false}
          onValidationChange={onValidationChange}
        />
      </div>

      <div className={getStepClass(1)}>
        <OperationFlagsForm />
      </div>

      <div className={getStepClass(2)}>
        <AccountingFlagForm isEmployee={isEmployee} />
      </div>

      <div className={getStepClass(3)}>
        <CommentForm />
      </div>

      {parsedLocation.claimId && (
        <div
          style={{
            display: "hidden",
          }}
        >
          <ReferenceArrayInput
            filterToQuery={(searchText) => ({
              reference: { startsWith: searchText },
            })}
            isRequired
            defaultValue={[parsedLocation.claimId]}
            source="claimsAsContact"
            reference="Claim"
            label="Claim"
          >
            <AutocompleteArrayInput
              suggestionLimit={5}
              optionText={ClaimTitle}
            />
          </ReferenceArrayInput>
        </div>
      )}
    </>
  );
};

export default UserCreateForm;
