import {
  Badge,
  CircularProgress,
  FormControlLabel,
  Grid,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AccountBalance,
  AttachMoneyOutlined,
  CloudDownloadOutlined,
  CreditCard,
  CloudDownloadOutlined as DownloadOutlined,
  HourglassEmptyOutlined,
  InfoOutlined,
  MonetizationOnRounded,
} from "@material-ui/icons";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import currency from "currency.js";
import { format } from "date-fns";
import jsonExport from "jsonexport/dist";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AutocompleteInput,
  Button,
  downloadCSV,
  LinearProgress,
  SelectInput,
  SimpleForm,
  Title,
  useDataProvider,
  useGetList,
  useNotify,
} from "react-admin";
import * as XLSX from "xlsx";
import { utils, writeFile } from "xlsx";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import { Payment } from "../api/payment/Payment";
import { User } from "../api/user/User";
import { NonInput } from "../Components/NonInputComponent";
import { useStyles, useTabsStyles } from "../Components/tabs/tabStyles";
import AppConfig from "../config/AppConfig";
import { theme } from "../theme/theme";
import { startsWithAnyTwoLetters } from "../util/ClaimUtils";
import { getDaysInMonth } from "../util/DateUtils";
import { getAuthToken } from "../util/JsonFilter";
import { searchMapByKey } from "../util/MapUtils";

const INCOME_GOAL = 3200000; // https://www.flyoke.com/listings/airbus-h125-for-sale/
const INCOME_GOAL_TITLE = "Airbus H125 Helicopter";

const today = new Date();
today.setHours(23);
today.setMinutes(59);

const PaymentOverview = () => {
  const actualCurrentDate: Date = new Date();
  const actualCurrentYear: number = actualCurrentDate.getFullYear();
  const [currentYear, setCurrentYear] = useState<number>(actualCurrentYear);
  const [currentMonth, setCurrentMonth] = useState<number>(
    actualCurrentDate.getMonth()
  );

  const [currentDate, setCurrentDate] = useState<number>(null);

  const availableMonths = [
    { label: "(none)", value: null },
    {
      label: "January",
      value: 0,
    },
    {
      label: "February",
      value: 1,
    },
    {
      label: "March",
      value: 2,
    },
    {
      label: "April",
      value: 3,
    },
    {
      label: "May",
      value: 4,
    },
    {
      label: "June",
      value: 5,
    },
    {
      label: "July",
      value: 6,
    },
    {
      label: "August",
      value: 7,
    },
    {
      label: "September",
      value: 8,
    },
    {
      label: "October",
      value: 9,
    },
    {
      label: "November",
      value: 10,
    },
    {
      label: "December",
      value: 11,
    },
  ];

  return (
    <div>
      <Grid container spacing={2}>
        <style>
          {`
        // whoever did this... needs to find Jesus.
  .shineEffect figure {
  margin: 0;
  position: relative;
  }
  .shineEffect figure::before {
  position: absolute;
  top: 0;
  left: -35%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  animation-iteration-count: 1;
    animation: shine 2.75s;
  }
  .shineEffect figure::before {
  -webkit-animation: shine 2.75s;
  animation: shine 2.75s;
  animation-iteration-count: infinite;
  }
  @-webkit-keyframes shine {
  100% {
  left: 125%;
  }
  }
  @keyframes shine {
  100% {
  left: 125%;
  }
  }
`}
        </style>
        <Title title="Payment Overview" />
        <SimpleForm
          component={Paper}
          toolbar={false}
          onSubmit={() => undefined}
          style={{
            width: "100%",
            padding: "1rem",
          }}
        >
          <NonInput>
            <div style={{ padding: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <SelectInput
                    source="paymentDateDate"
                    alwaysOn
                    label="Day"
                    value={currentDate}
                    defaultValue={currentDate}
                    onChange={(e) => {
                      setCurrentDate(e.target.value as unknown as number);
                    }}
                    choices={[
                      { label: "(none)", value: 0 },
                      ...getDaysInMonth(currentMonth),
                    ]}
                    optionText="label"
                    optionValue="value"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <SelectInput
                    source="paymentDateMonths"
                    alwaysOn
                    label="Month"
                    value={currentMonth}
                    onChange={(e) => {
                      setCurrentMonth(e.target.value as unknown as number);
                    }}
                    choices={availableMonths}
                    optionText="label"
                    optionValue="value"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <SelectInput
                    source="paymentDateYear"
                    alwaysOn
                    label="Year"
                    value={currentYear}
                    onChange={(e) =>
                      setCurrentYear(e.target.value as unknown as number)
                    }
                    choices={[
                      { label: "(none)", value: null },
                      {
                        label: actualCurrentYear.toString(),
                        value: actualCurrentYear,
                      },
                      {
                        label: (actualCurrentYear - 1).toString(),
                        value: new Date(
                          actualCurrentYear - 1,
                          1,
                          1
                        ).getFullYear(),
                      },
                      {
                        label: (actualCurrentYear - 2).toString(),
                        value: new Date(
                          actualCurrentYear - 2,
                          1,
                          1
                        ).getFullYear(),
                      },
                      {
                        label: (actualCurrentYear - 3).toString(),
                        value: new Date(
                          actualCurrentYear - 3,
                          1,
                          1
                        ).getFullYear(),
                      },
                      {
                        label: (actualCurrentYear - 4).toString(),
                        value: new Date(
                          actualCurrentYear - 4,
                          1,
                          1
                        ).getFullYear(),
                      },
                    ]}
                    optionText="label"
                    optionValue="value"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ minWidth: "100%", width: "100%" }}
              >
                <Grid item xs={12}>
                  <PayoutList
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                    currentDate={currentDate}
                  />
                </Grid>
              </Grid>

              <ClaimFileUpload />
            </div>
          </NonInput>
        </SimpleForm>
      </Grid>
    </div>
  );
};

const ClaimFileUpload = () => {
  const dataProvider = useDataProvider();
  const [creditorId, setCreditorId] = useState("");

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: {
          Authorization: getAuthToken(),
        },
      };

      const files = event.target.files;

      if (!creditorId) {
        return;
      }

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            let claimTitle = file.name.split(".")[0];
            const claimTitleSplit = claimTitle.split("_");
            if (claimTitleSplit.length > 1) {
              claimTitle = claimTitleSplit[0];
            }

            if (claimTitle) {
              const { data: claimData } = await dataProvider.getList("Claim", {
                pagination: { page: 1, perPage: 3 },
                sort: { field: "createdAt", order: "DESC" },
                filter: {
                  title: { contains: claimTitle },
                  creditor: { id: creditorId },
                },
              });

              await fetch(
                process.env.REACT_APP_SERVER_URL +
                  "/api/claims/" +
                  (claimData[0] as Claim).id +
                  "/files",
                {
                  method: "POST",
                  body: formData,
                  ...authHeader,
                }
              );

              // Optional: You can add a delay between each request
              await new Promise((resolve) => setTimeout(resolve, 250));
            }
          }
        } catch (error) {}
      }
    },
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography component={"span"}>{"Claim files:"}</Typography>
        <Input
          type="file"
          {...uploadProps}
          inputProps={{ multiple: true }}
          style={{ marginTop: "1rem" }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component={"span"} variant="subtitle2">
          Creditor ID
        </Typography>
        <TextField
          fullWidth
          type="text"
          placeholder="cm5fj0won0009ye3qgp4xul90"
          value={creditorId}
          onChange={(e) => setCreditorId(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

// Helper component to render table headers
function TableHeader({ creditors }: { creditors: string[] }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        {creditors.map((creditor) => (
          <TableCell key={creditor} align="right">
            {creditor}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Helper component to render table cells for a specific row
function TableRowComponent({
  date,
  creditors,
  totals,
  dailyTotal,
}: {
  date: string;
  creditors: string[];
  totals: CreditorProfits;
  dailyTotal?: number;
}) {
  return (
    <TableRow
      key={date}
      style={
        date === "Total" ? { backgroundColor: theme.palette.grey[100] } : {}
      }
    >
      <TableCell>
        {date}{" "}
        {!!dailyTotal && (
          <strong>
            (
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(dailyTotal)}
            )
          </strong>
        )}
      </TableCell>
      {creditors.map((creditor) => (
        <TableCell key={creditor} align="right">
          {!!totals[creditor]?.total
            ? Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals[creditor].total)
            : date === "Manager"
            ? totals[creditor]?.manager || "Probably Tony" // fallback when no manager is set
            : date === "Commission"
            ? totals[creditor]?.commission
            : ""}
        </TableCell>
      ))}
    </TableRow>
  );
}

// Main component to render the entire table with MUI
function GroupedClaimsTable({
  claims,
  currentMonth,
  currentYear,
}: {
  claims: any[];
  currentMonth?: number;
  currentYear?: number;
}) {
  const groupedClaims = groupClaimsByDayAndCreditor(
    claims,
    currentMonth,
    currentYear
  );

  // Extract all unique creditors
  const allCreditors = Array.from(
    new Set(groupedClaims.flatMap(([_, data]) => Object.keys(data.creditors)))
  );

  const exportXls = () => {
    try {
      // Format function to handle the date
      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Prepare data for export
      const creditorRows = [];
      const managerRow = { Date: "Manager" };
      const commissionRow = { Date: "Commission" };

      groupedClaims.forEach(([date, obj]) => {
        const formattedDate = formatDate(date);
        const creditors = obj.creditors || {};

        // Create a row for creditors
        const creditorRow = { Date: formattedDate };

        Object.keys(creditors).forEach((creditorName) => {
          const data = creditors[creditorName];
          creditorRow[creditorName] = data.total;

          // Add manager info to the managerRow (only once per creditor)
          if (!managerRow[creditorName]) {
            managerRow[creditorName] = data.manager;
          }

          // Add manager info to the commissionRow (only once per creditor)
          if (!commissionRow[creditorName]) {
            commissionRow[creditorName] = data.commission;
          }
        });

        creditorRows.push(creditorRow);
      });

      // Combine all rows: creditor rows + single manager row
      const flattenedData = [managerRow, commissionRow, ...creditorRows];

      // Convert the array of objects to a worksheet
      const worksheet = utils.json_to_sheet(flattenedData);

      // Create a new workbook and append the worksheet
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Claims");

      // Write the file (download it on the browser)
      writeFile(workbook, "claims.xlsx", { bookType: "xlsx" });
    } catch (err) {
      console.error("Error exporting to XLS", err);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginBottom: "1rem",
        }}
      >
        <Table stickyHeader>
          <TableHeader creditors={allCreditors} />
          <TableBody>
            <TableRowComponent
              key="manager"
              date="Manager"
              creditors={allCreditors}
              totals={groupedClaims.reduce((acc, [, data]) => {
                Object.entries(data.creditors).forEach(([creditor, data]) => {
                  acc[creditor] = { manager: data?.manager };
                });
                return acc;
              }, {})}
            />
            <TableRowComponent
              key="commission"
              date="Commission"
              creditors={allCreditors}
              totals={groupedClaims.reduce((acc, [, data]) => {
                Object.entries(data.creditors).forEach(([creditor, data]) => {
                  acc[creditor] = { commission: data.commission };
                });
                return acc;
              }, {})}
            />
            {groupedClaims.map(([date, data]) => (
              <TableRowComponent
                key={date}
                date={date}
                creditors={allCreditors}
                totals={data.creditors}
                dailyTotal={data.total}
              />
            ))}
            <TableRowComponent
              key="total"
              date="Total"
              creditors={allCreditors}
              totals={groupedClaims.reduce((acc, [, data]) => {
                Object.entries(data.creditors).forEach(([creditor, data]) => {
                  acc[creditor] = {
                    total: (acc[creditor]?.total || 0) + data?.total,
                  };
                });
                return acc;
              }, {})}
              dailyTotal={groupedClaims.reduce(
                (acc, [, data]) => acc + data.total,
                0
              )}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        size="small"
        onClick={() => exportXls()}
        label="Export to Excel"
      />
    </>
  );
}

// Type definition for the grouped claims
interface CreditorProfits {
  [creditor: string]: {
    total: number;
    manager: string;
    commission: string;
  }; // creditor name as key and their total profit as value
}

interface GroupedData {
  creditors: CreditorProfits;
  total: number; // total profit for the day
}

function groupClaimsByDayAndCreditor(
  claims: any[],
  currentMonth?: number,
  currentYear?: number
): [string, GroupedData][] {
  const currentDate = new Date();
  const effectiveMonth =
    currentMonth !== null && currentMonth !== undefined
      ? currentMonth
      : currentDate.getMonth();
  currentYear = currentYear || currentDate.getFullYear();

  const grouped = claims?.reduce((acc, claim) => {
    const claimDate = new Date(claim.lastPaymentDate);
    const paymentDate = !isNaN(claimDate.getTime())
      ? claimDate.toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0]; // Default to current date if invalid
    // Check if the claim is in the current month and year
    if (
      claimDate.getMonth() !== effectiveMonth ||
      claimDate.getFullYear() !== currentYear
    ) {
      return acc; // Skip claims not in the current month
    }

    const creditor =
      claim.creditor?.businessName ||
      claim.creditor?.contactName ||
      "Unknown Creditor";
    if (!acc[paymentDate]) {
      acc[paymentDate] = { creditors: {}, total: 0 }; // Initialize date group with total
    }
    if (!acc[paymentDate].creditors[creditor]) {
      acc[paymentDate].creditors[creditor] = { total: 0 }; // Initialize creditor sum for this day
    }
    acc[paymentDate].creditors[creditor].total +=
      claim.totalProfit > 0 ? claim.totalProfit : 0;
    acc[paymentDate].total += claim.totalProfit > 0 ? claim.totalProfit : 0; // Add to total profit for the day

    acc[paymentDate].creditors[creditor].manager = !!claim.creditor?.manager
      ?.contactName
      ? `${claim.creditor?.manager?.contactName?.split(" ")[0]}`
      : "";
    acc[paymentDate].creditors[creditor].commission =
      claim.creditor?.utmSource?.toLowerCase() === "outbound" ? "0,08" : "0,02";

    return acc;
  }, {} as { [key: string]: GroupedData });

  // Filter and sort
  // @ts-ignore
  return Object.entries(grouped)
    .filter(([_, data]) => Object.keys((data as any).creditors).length > 0)
    .sort(
      ([dateA], [dateB]) =>
        new Date(dateB)?.getTime() - new Date(dateA)?.getTime()
    );
}

const PaymentUpload = () => {
  const authHeader = {
    headers: {
      Authorization: getAuthToken(),
    },
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue = ""; // Show confirmation dialog when uploading
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  const notify = useNotify();
  const bankFileUploadProps = {
    name: "file",
    multiple: true,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        setIsLoading(true);

        fetch(process.env.REACT_APP_SERVER_URL + "/api/payments/create/file", {
          method: "POST",
          body: formData,
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              const successItems = responseJson.filter((responseLine: any) =>
                responseLine.status.startsWith("Success")
              );
              const successCount = successItems.length;
              const failedCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Failed"
              ).length;
              const unknownCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Unknown"
              ).length;
              const duplicateCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Duplicate"
              ).length;

              if (failedCount > 0 || unknownCount > 0 || duplicateCount > 0) {
                notify(
                  `${responseJson.length} Payments uploaded. ${successCount} success, ${failedCount} failed, ${unknownCount} unknown, ${duplicateCount} duplicate`,
                  "warning",
                  null,
                  false
                );
              } else {
                notify(
                  `${successCount} Payments uploaded successfully`,
                  "success",
                  null,
                  false
                );
              }

              try {
                const result = [];
                responseJson.forEach((item) => {
                  const flattenedItem = {};

                  function flatten(current, property) {
                    if (Object(current) !== current || Array.isArray(current)) {
                      // Check if the value is a string with comma and replace with period
                      if (
                        typeof current === "string" &&
                        current.match(/^\d+,\d+$/)
                      ) {
                        flattenedItem[property] = parseFloat(
                          current.replace(",", ".")
                        );
                      } else {
                        flattenedItem[property] = current;
                      }
                    } else {
                      for (const key in current) {
                        if (current.hasOwnProperty(key)) {
                          flatten(
                            current[key],
                            property ? `${property}.${key}` : key
                          );
                        }
                      }
                    }
                  }

                  flatten(item, "");
                  result.push(flattenedItem);
                });

                // Convert the array of objects to a worksheet
                const worksheet = utils.json_to_sheet(result);

                // Format numbers in the worksheet
                const range = utils.decode_range(worksheet["!ref"]);
                for (let row = range.s.r; row <= range.e.r; row++) {
                  for (let col = range.s.c; col <= range.e.c; col++) {
                    const cellAddress = { r: row, c: col };
                    const cellRef = utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];

                    if (cell && typeof cell.v === "number") {
                      cell.t = "n"; // Mark cell as number
                      cell.z = "0.00"; // Apply number format, adjust if needed
                    }
                  }
                }

                const workbook = utils.book_new();

                // Append the worksheet to the workbook
                utils.book_append_sheet(workbook, worksheet, "Payments");

                // Write the file (download it on the browser)
                writeFile(
                  workbook,
                  "Payments_" + new Date().toISOString() + ".xlsx",
                  { bookType: "xlsx" }
                );
              } catch (err) {
                console.error("Error exporting to XLS", err);
              }

              setIsLoading(false);

              if (successCount > 0) {
                const today = new Date();
                const SEPA = require("sepa");
                const sepaExportDoc = new SEPA.Document("pain.001.001.03");
                sepaExportDoc.grpHdr.id =
                  "debtist-profits-" + format(today, "dd-MM-yyyy");
                sepaExportDoc.grpHdr.created = new Date();
                sepaExportDoc.grpHdr.initiatorName =
                  AppConfig.bank.accountHolder;

                const info = sepaExportDoc.createPaymentInfo();
                info.requestedExecutionDate = new Date();
                info.debtorIBAN = AppConfig.bank.iban;
                info.debtorBIC = AppConfig.bank.bic;
                info.debtorName = AppConfig.bank.accountHolder;
                info.batchBooking = false;
                sepaExportDoc.addPaymentInfo(info);
                for (const item of successItems) {
                  const debtistPayment = currency(item.debtistPayment || "0", {
                    separator: ".",
                    decimal: ",",
                  }).value;

                  if (debtistPayment && debtistPayment > 0) {
                    const cleanedReference = item.claimReference
                      .replaceAll(" ", "-")
                      .replaceAll("(", "")
                      .replaceAll(")", "")
                      .replaceAll(":", "")
                      .replaceAll("&", "+")
                      .replaceAll("ß", "ss")
                      .replaceAll("ü", "ue")
                      .replaceAll("ö", "oe")
                      .replaceAll("ä", "ae")
                      .replaceAll("Ü", "Ue")
                      .replaceAll("Ö", "Oe")
                      .replaceAll("Ä", "Ae");
                    const tx = info.createTransaction();
                    tx.creditorName =
                      AppConfig.bank.companyAccount.accountHolder;
                    tx.creditorIBAN = AppConfig.bank.companyAccount.iban;
                    tx.amount = debtistPayment;
                    tx.remittanceInfo = cleanedReference;
                    tx.end2endId =
                      cleanedReference.substring(0, 14) + // only the claim reference
                      "-" +
                      format(today, "dd-MM-yyyy") +
                      "-1";
                    try {
                      info.addTransaction(tx);
                    } catch (e) {
                      console.error("issue in " + item.claimReference);
                      throw e;
                    }
                  }
                }

                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                const blobby = new Blob([sepaExportDoc.toString()], {
                  type: "text/xml;charset=utf8",
                });

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download =
                  "debtist-profits-" + format(today, "dd-MM-yyyy");
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
              }
            }
          })
          .catch((e) => {
            notify("Upload failed", "error", null, false);
          });
      }
    },
  };

  const excelFileUploadProps = {
    name: "file",
    multiple: true,
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Convert the sheet to JSON
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract header and data rows
          const [header, ...dataRows] = rows as string[][];

          if (!header) {
            console.error("Header row not found in the Excel file.");
            return;
          }

          const claimReferenceIndex = header.indexOf("claimReference");
          const debtistPaymentIndex = header.indexOf("debtistPayment");
          const returnedPaymentIndex = header.indexOf("returnedPayment");

          const senderIndex = header.indexOf("line.Kontrahent");
          const debtorIndex = header.indexOf("line.Debitor");
          const senderAccountIndex = header.indexOf("line.Kontrahentkonto");
          const debtorAccountIndex = header.indexOf("line.Debitor Konto");

          if (
            claimReferenceIndex === -1 ||
            debtistPaymentIndex === -1 ||
            returnedPaymentIndex === -1 ||
            senderIndex === -1 ||
            senderAccountIndex === -1 ||
            debtorIndex === -1 ||
            debtorAccountIndex === -1
          ) {
            console.error(
              "Required columns not found in the Excel file.",
              claimReferenceIndex === -1,
              debtistPaymentIndex === -1,
              returnedPaymentIndex === -1,
              senderIndex === -1,
              senderAccountIndex === -1,
              debtorIndex === -1,
              debtorAccountIndex === -1
            );
            return;
          }

          // Prepare SEPA document
          const SEPA = require("sepa");
          const today = new Date();
          const sepaExportDoc = new SEPA.Document("pain.001.001.03");
          sepaExportDoc.grpHdr.id =
            "debtist-profits-" + format(today, "dd-MM-yyyy");
          sepaExportDoc.grpHdr.created = today;
          sepaExportDoc.grpHdr.initiatorName = AppConfig.bank.accountHolder;

          const info = sepaExportDoc.createPaymentInfo();
          info.requestedExecutionDate = today;
          info.debtorIBAN = AppConfig.bank.iban;
          info.debtorBIC = AppConfig.bank.bic;
          info.debtorName = AppConfig.bank.accountHolder;
          info.batchBooking = false;
          sepaExportDoc.addPaymentInfo(info);

          // Process rows
          for (const row of dataRows) {
            const claimReference = row[claimReferenceIndex]?.toString();
            const debtistPaymentRaw = row[debtistPaymentIndex]?.toString();
            const returnedPaymentRaw = row[returnedPaymentIndex]?.toString();

            const debtistPayment = currency(debtistPaymentRaw || "0", {
              separator: ",",
              decimal: ".",
            }).value;
            const returnedPayment = currency(returnedPaymentRaw || "0", {
              separator: ",",
              decimal: ".",
            }).value;

            if (claimReference && debtistPayment > 0) {
              const cleanedReference = claimReference
                .replaceAll(" ", "-")
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll(":", "")
                .replaceAll("&", "+")
                .replaceAll("ß", "ss")
                .replaceAll("ü", "ue")
                .replaceAll("ö", "oe")
                .replaceAll("ä", "ae")
                .replaceAll("Ü", "Ue")
                .replaceAll("Ö", "Oe")
                .replaceAll("Ä", "Ae");

              const tx = info.createTransaction();
              tx.creditorName = AppConfig.bank.companyAccount.accountHolder;
              tx.creditorIBAN = AppConfig.bank.companyAccount.iban;
              tx.amount = debtistPayment;
              tx.remittanceInfo = cleanedReference;
              tx.end2endId =
                cleanedReference.substring(0, 14) +
                "-" +
                format(today, "dd-MM-yyyy") +
                "-1";

              try {
                info.addTransaction(tx);
              } catch (e) {
                console.error("Error in transaction: " + claimReference);
                throw e;
              }
            }

            if (claimReference && returnedPayment > 0) {
              const cleanedReference =
                claimReference
                  .replaceAll(" ", "-")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll(":", "")
                  .replaceAll("&", "+")
                  .replaceAll("ß", "ss")
                  .replaceAll("ü", "ue")
                  .replaceAll("ö", "oe")
                  .replaceAll("ä", "ae")
                  .replaceAll("Ü", "Ue")
                  .replaceAll("Ö", "Oe")
                  .replaceAll("Ä", "Ae") +
                "-" +
                "Retoure";

              const sender =
                row[debtorIndex]?.toString() || row[senderIndex]?.toString();
              const senderAccount =
                row[debtorAccountIndex]?.toString() ||
                row[senderAccountIndex]?.toString();
              const tx = info.createTransaction();
              tx.creditorName = sender;
              tx.creditorIBAN = senderAccount;
              tx.amount = returnedPayment;
              tx.remittanceInfo = cleanedReference;
              tx.end2endId =
                cleanedReference.substring(0, 14) +
                "-" +
                format(today, "dd-MM-yyyy") +
                "-2";

              try {
                info.addTransaction(tx);
              } catch (e) {
                console.error("Error in transaction: " + claimReference);
                throw e;
              }
            }
          }

          // Create and download the SEPA file
          const anchor = document.createElement("a");
          document.body.appendChild(anchor);
          const blobby = new Blob([sepaExportDoc.toString()], {
            type: "text/xml;charset=utf8",
          });

          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = "debtist-payments-" + format(today, "dd-MM-yyyy");
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        };

        reader.readAsArrayBuffer(file);
      }
    },
  };

  return (
    <Grid container style={{ width: "100%", minWidth: "100%" }}>
      <Grid item xs={6}>
        <Typography component={"span"} variant="h6">
          Upload Bank file: {isLoading && <CircularProgress size={16} />}
        </Typography>
        <Input
          type="file"
          {...bankFileUploadProps}
          style={{ marginTop: "1rem" }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component={"span"} variant="h6">
          Upload Payment Excel: {isLoading && <CircularProgress size={16} />}
        </Typography>
        <Input
          type="file"
          {...excelFileUploadProps}
          style={{ marginTop: "1rem" }}
        />
      </Grid>
    </Grid>
  );
};

const PayoutList = (props: {
  currentMonth: number | null;
  currentYear: number;
  currentDate: number | null;
}): React.ReactElement => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const [claims, setClaims] = useState<Claim[]>([]);
  const [showPendingPayouts, setShowPendingPayouts] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creditorFilterValues, setCreditorFilterValues] = useState<object[]>(
    []
  );
  const [activePayoutCreditorFilter, setActivePayoutCreditorFilter] =
    useState("");
  const [stageFilter, setStageFilter] = useState("");
  const [ignoreDates, setIgnoreDates] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [newUnconfirmedPayments, setNewUnconfirmedPayments] = useState(false);

  const [totals, setTotals] = useState({
    sum: 0,
    toBePaidOut: 0,
    paidOut: 0,
    income: 0,
    tax: 0,
    totalProfit: 0,
  });

  const { data: unconfirmedPaymentsDebtClearance } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    {
      isPlanned: {
        equals: true,
      },
      paymentType: "DebtClearance",
    }
  );

  const { data: unconfirmedPaymentsPaymentRate } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    {
      isPlanned: {
        equals: true,
      },
      paymentType: "PaymentRate",
    }
  );

  const unconfirmedPayments = useMemo((): Payment[] => {
    return [
      ...(Object.values(unconfirmedPaymentsDebtClearance) || []),
      ...(Object.values(unconfirmedPaymentsPaymentRate) || []),
    ];
  }, [unconfirmedPaymentsDebtClearance, unconfirmedPaymentsPaymentRate]);

  useEffect(() => {
    let url = process.env.REACT_APP_SERVER_URL + "/api/payments/overview?";
    if (activePayoutCreditorFilter) {
      url = url + "creditor[id]=" + activePayoutCreditorFilter + "&";
    }
    if (stageFilter) {
      url = url + "stage=" + stageFilter + "&";
    }
    if (ignoreDates) {
      url = url + "ignoreDates=true&";
    }

    if (props.currentYear !== null && props.currentMonth !== null) {
      const maximumDate = new Date(
        props.currentYear,
        (props.currentDate
          ? -1 + props.currentMonth
          : props.currentMonth || 0) + 1,
        (props.currentDate || 0) + 1,
        23,
        59
      );
      maximumDate.setDate(maximumDate.getDate() - 1);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, props.currentMonth || 0, 1, 0, 0);
    } else if (props.currentYear !== null) {
      const maximumDate = new Date(props.currentYear, 11, 31, 23, 59);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, 0, 1, 0, 0);
    }

    setIsLoading(true);
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
      },
    };
    fetch(url, authHeader)
      .then((response) => response.json())
      .then((responseJson) => {
        if (!!responseJson) {
          setClaims(responseJson);
        }
        setIsLoading(false);
      });
  }, [
    activePayoutCreditorFilter,
    stageFilter,
    ignoreDates,
    props.currentMonth,
    props.currentYear,
    props.currentDate,
  ]);

  useEffect(() => {
    const totalIncomingSum = claims?.reduce(function (a, b: any) {
      return a + (b.incomingPaymentsTotal > 0 ? b.incomingPaymentsTotal : 0);
    }, 0);

    const toBePaidOutSum = claims?.reduce(function (a, b: any) {
      return a + (b.toBePaidOutTotal > 0 ? b.toBePaidOutTotal : 0);
    }, 0);

    const paidOutSum = claims?.reduce(function (a, b: any) {
      return a + (b.paidOutTotal > 0 ? b.paidOutTotal : 0);
    }, 0);

    const taxSum = claims?.reduce(function (a, b: any) {
      return a + (b.tax > 0 ? b.tax : 0);
    }, 0);

    const totalProfitSum = claims?.reduce(function (a, b: any) {
      return a + (b.totalProfit > 0 ? b.totalProfit : 0);
    }, 0);

    let newCreditorFilterValues = [{ label: "(none)", value: "" }];
    // Group creditors by their id
    const creditorMap: { [key: string]: { user: User; totalProfit: number } } =
      {};
    for (const claim of claims) {
      const creditorId = claim.creditor.id;
      if (creditorMap[creditorId]) {
        creditorMap[creditorId].totalProfit += (claim as any).totalProfit;
      } else {
        creditorMap[creditorId] = {
          user: claim.creditor,
          totalProfit: (claim as any).totalProfit,
        };
      }
    }

    // Convert the map to an array
    const creditorArray = Object.values(creditorMap);

    // Sort the array based on the sum of their claims' totalProfit
    creditorArray.sort((a, b) => b.totalProfit - a.totalProfit);

    // Convert the array to the desired format
    newCreditorFilterValues = newCreditorFilterValues.concat(
      creditorArray.map((creditor) => ({
        label: creditor.user.businessName || "",
        value: creditor.user.id || "",
      }))
    );

    if (!activePayoutCreditorFilter || newCreditorFilterValues.length > 2) {
      setCreditorFilterValues(newCreditorFilterValues);
    }

    setTotals({
      sum: totalIncomingSum,
      toBePaidOut: toBePaidOutSum,
      paidOut: paidOutSum,
      income: totalIncomingSum - toBePaidOutSum - paidOutSum - taxSum,
      tax: taxSum,
      totalProfit: totalProfitSum,
    });
  }, [activePayoutCreditorFilter, claims]);

  const incomeGoalStatus = (totals.income / INCOME_GOAL) * 100;

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  useEffect(() => {
    const lastCheck = localStorage.getItem("lastUnconfirmedCheck");
    const lastCheckDate = lastCheck ? new Date(lastCheck) : new Date(0);

    const hasNewPayments =
      Array.isArray(unconfirmedPayments) &&
      unconfirmedPayments.some(
        (payment) => new Date(payment.paymentDate) > lastCheckDate
      );

    setNewUnconfirmedPayments(hasNewPayments);

    if (tabValue === 1) {
      localStorage.setItem("lastUnconfirmedCheck", new Date().toISOString());
      setNewUnconfirmedPayments(false);
    }
  }, [unconfirmedPayments, tabValue]);

  return (
    <Grid container style={{ width: "100%", minWidth: "100%" }}>
      <Grid item xs={12} md={8}>
        <Typography
          component={"span"}
          variant="h5"
          style={{ marginBottom: 10 }}
        >
          Progress {isLoading && <CircularProgress size={16} />}
        </Typography>
        <List dense style={{ maxWidth: 400 }}>
          <ListItem>
            <ListItemIcon style={{ width: "30%" }}>📨 Collected:</ListItemIcon>
            <ListItemText style={{ textAlign: "right" }}>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals.sum)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ width: "45%" }}>
              ⏳ Pending payout:
            </ListItemIcon>
            <ListItemText style={{ textAlign: "right" }}>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals.toBePaidOut)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ width: "45%" }}>💸 Paid out:</ListItemIcon>
            <ListItemText style={{ textAlign: "right" }}>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals.paidOut)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ width: "45%" }}>🥷 VAT:</ListItemIcon>
            <ListItemText style={{ textAlign: "right" }}>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals.tax)}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ width: "45%" }}>💰 Revenue:</ListItemIcon>
            <ListItemText style={{ textAlign: "right", fontWeight: "bold" }}>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals.income)}
            </ListItemText>
          </ListItem>
          {((props.currentMonth !== null && props.currentYear) ||
            props.currentYear !== null) && (
            <ListItem>
              <ListItemIcon style={{ width: "60%" }}>
                💰 Revenue (only{" "}
                {(props.currentMonth !== null
                  ? props.currentMonth + 1 + "/"
                  : "") + props.currentYear}
                ):
              </ListItemIcon>
              <ListItemText style={{ textAlign: "right", fontWeight: "bold" }}>
                {Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(totals.totalProfit)}
              </ListItemText>
            </ListItem>
          )}
        </List>
        <Typography component={"span"} variant="body1">
          <>
            <LinearProgress
              variant="buffer"
              color={"secondary"}
              style={{ borderRadius: 4, width: "50%" }}
              value={Math.min(incomeGoalStatus, 100)}
              valueBuffer={100}
            />
            <br />
            {"Target: "}
            <EmojiEvents
              style={{
                height: 16,
                marginBottom: -2,
                color: theme.palette.secondary.main,
                width: 16,
              }}
            />
            <b
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              {incomeGoalStatus.toFixed(2) + "%"}
            </b>
          </>
        </Typography>

        <Grid container spacing={2} style={{ minWidth: "100%", width: "100%" }}>
          <Grid item xs={12} lg={6}>
            <AutocompleteInput
              source="creditorId"
              alwaysOn
              label="Creditor Filter"
              value={activePayoutCreditorFilter}
              onSelect={(e) => {
                setActivePayoutCreditorFilter(e.value);
              }}
              clearAlwaysVisible
              choices={creditorFilterValues}
              optionText="label"
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <SelectInput
              source="stage"
              alwaysOn
              label="Stage Filter"
              defaultValue={stageFilter}
              value={stageFilter}
              onChange={(e) => {
                if (e.target.value) {
                  setStageFilter(e.target.value);
                }
              }}
              choices={[
                {
                  label: "All",
                  value: "",
                },
                {
                  label: "Precourt",
                  value: "Precourt",
                },
                {
                  label: "Monitoring",
                  value: "Monitoring",
                },
                {
                  label: "Court",
                  value: "Court",
                },
              ]}
              optionText="label"
              optionValue="value"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          overflow: "hidden",
        }}
      >
        <div className="shineEffect">
          <figure style={{ margin: 0 }}>
            <img
              alt={INCOME_GOAL_TITLE}
              src={`${process.env.PUBLIC_URL}/Airbus-H125.jpeg`}
              style={{ width: "100%", borderRadius: 8 }}
            />
          </figure>
          <span
            style={{
              display: "block",
              textAlign: "center",
              fontSize: 12,
              marginTop: 10,
            }}
          >
            {INCOME_GOAL_TITLE}
          </span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          onChange={handleChange}
          value={tabValue}
          aria-label={"payment overview tabs"}
          style={{
            marginTop: 15,
            marginBottom: 15,
            background: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 8,
            maxWidth: "100%",
          }}
          variant={"scrollable"}
          scrollButtons={"auto"}
          classes={{ indicator: tabsClasses.MuiTabsIndicator }}
        >
          <Tab
            label={"Pending"}
            classes={{ selected: classes.selected }}
            icon={
              <HourglassEmptyOutlined
                className={tabValue === 0 ? classes.icon : ""}
              />
            }
          />
          <Tab
            label={
              <Badge
                color="error"
                badgeContent={newUnconfirmedPayments ? "New" : ""}
                invisible={!newUnconfirmedPayments}
                overlap="circular"
              >
                {"Unconfirmed"}
              </Badge>
            }
            classes={{ selected: classes.selected }}
            icon={
              <InfoOutlined className={tabValue === 1 ? classes.icon : ""} />
            }
          />
          <Tab
            label={"Revenue"}
            classes={{ selected: classes.selected }}
            icon={
              <AttachMoneyOutlined
                className={tabValue === 2 ? classes.icon : ""}
              />
            }
          />
        </Tabs>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 0}
          id={`vertical-tabpanel-${0}`}
        >
          <Typography component={"span"} variant="h6">
            Pending payout (
            <span
              style={{ color: theme.palette.primary.main, cursor: "pointer" }}
              onClick={() => {
                setShowPendingPayouts(!showPendingPayouts);
              }}
            >
              Toggle
            </span>
            )
          </Typography>
          <FormControlLabel
            label="Ignore Dates"
            value={ignoreDates}
            onChange={(_e, checked) => {
              setIgnoreDates(checked);
            }}
            disabled={isLoading}
            control={<Switch color="secondary" defaultChecked={ignoreDates} />}
            labelPlacement={"start"}
          />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Payment Date</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showPendingPayouts &&
                  claims &&
                  claims.length > 0 &&
                  claims.map((claim: Claim & { toBePaidOutTotal?: number }) => {
                    if (
                      claim.toBePaidOutTotal &&
                      claim.toBePaidOutTotal > 0 &&
                      claim.status !== "Closed"
                    ) {
                      return (
                        <TableRow key={claim.id}>
                          <TableCell>
                            <a href={"#/Claim/" + claim.id + "/show/1"}>
                              {claim.title && claim.reference}:
                            </a>
                          </TableCell>

                          <TableCell>{claim.id}</TableCell>
                          <TableCell>
                            {claim.title && claim.reference}
                          </TableCell>
                          <TableCell>
                            {" " +
                              Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(claim.toBePaidOutTotal || 0)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <PaymentUpload />
          <UnpaidList
            currentMonth={props.currentMonth}
            currentYear={props.currentYear}
            currentDate={props.currentDate}
          />
        </div>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 1}
          id={`vertical-tabpanel-${1}`}
        >
          {unconfirmedPayments && unconfirmedPayments.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Payment Type</TableCell>
                      <TableCell>Payment Date</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {unconfirmedPayments.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>
                          <a href={"#/Payment/" + payment.id + "/show/1"}>
                            {payment.id}
                          </a>
                        </TableCell>
                        <TableCell>{payment.paymentType}</TableCell>
                        <TableCell>{payment.paymentDate}</TableCell>
                        <TableCell>{payment.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 2}
          id={`vertical-tabpanel-${2}`}
        >
          {!!claims && !!claims.length && (
            <GroupedClaimsTable
              claims={claims}
              currentMonth={props.currentMonth}
              currentYear={props.currentYear}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const UnpaidList = (props: {
  currentMonth: number | null;
  currentYear: number;
  currentDate: number | null;
}): React.ReactElement => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [markForeignCurrencyPaid, setMarkForeignCurrencyPaid] =
    useState<boolean>(false);
  const [enableOnlinePayments, setEnableOnlinePayments] =
    useState<boolean>(false);
  const [enableMonthlyBilling, setEnableMonthlyBilling] =
    useState<boolean>(false);

  const today = new Date();

  useEffect(() => {
    let url = process.env.REACT_APP_SERVER_URL + "/api/payments/unpaid?";
    if (props.currentYear !== null && props.currentMonth !== null) {
      const maximumDate = new Date(
        props.currentYear,
        (props.currentDate
          ? -1 + props.currentMonth
          : props.currentMonth || 0) + 1,
        (props.currentDate || 0) + 1,
        23,
        59
      );
      maximumDate.setDate(maximumDate.getDate() - 1);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, props.currentMonth || 0, 1, 0, 0);
    }

    setIsLoading(true);
    const authHeader = {
      headers: {
        Authorization: getAuthToken(),
      },
    };
    fetch(url, authHeader)
      .then((response) => response.json())
      .then((responseJson) => {
        setClaims(responseJson);
        setIsLoading(false);
      });
  }, [props.currentMonth, props.currentYear, props.currentDate]);

  const SEPA = require("sepa");
  const sepaExportDoc = new SEPA.Document("pain.001.001.03");
  sepaExportDoc.grpHdr.id = `payouts-${format(today, "yyMMdd")}`;

  sepaExportDoc.grpHdr.created = new Date();
  sepaExportDoc.grpHdr.initiatorName = AppConfig.bank.accountHolder;

  const info = sepaExportDoc.createPaymentInfo();
  info.requestedExecutionDate = new Date();
  info.debtorIBAN = AppConfig.bank.iban;
  info.debtorBIC = AppConfig.bank.bic;
  info.debtorName = AppConfig.bank.accountHolder;
  info.batchBooking = false;
  sepaExportDoc.addPaymentInfo(info);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const foreignCurrencyClaims = [];

  const uniqueIds = new Set<string>();

  return (
    <div>
      {claims && claims.length > 0 && (
        <div className="tab-container">
          <Button
            onClick={() => {
              let anchor = document.createElement("a");
              document.body.appendChild(anchor);
              const blobby = new Blob([sepaExportDoc.toString()], {
                type: "text/xml;charset=utf8",
              });

              const objectUrl = window.URL.createObjectURL(blobby);
              anchor.href = objectUrl;
              anchor.download =
                "debtist-payouts-" + format(today, "dd-MM-yyyy");
              anchor.click();

              window.URL.revokeObjectURL(objectUrl);

              try {
                // Format function to handle the date
                const successWorkSheet = [];
                for (const claim of claims) {
                  const hasOnlinePayment = claim.payments?.some(
                    (payment: Payment) => {
                      return payment.reference
                        ?.toLowerCase()
                        .includes("online payment");
                    }
                  );
                  let useMonthlyBilling = claim.creditor?.useMonthlyBilling;

                  const paymentInformation =
                    claim.creditor?.paymentInformations.filter(
                      (paymentInformation) =>
                        paymentInformation.currency === "EUR" ||
                        !paymentInformation.currency
                    )[0];

                  if (
                    paymentInformation &&
                    startsWithAnyTwoLetters(paymentInformation.account) &&
                    (claim.currency === "EUR" || !claim.currency) &&
                    (!hasOnlinePayment || enableOnlinePayments)
                  ) {
                    for (const payment of claim.payments.filter(
                      (payment) =>
                        payment.paymentType === "Payout" && !!payment?.fileUrl
                    )) {
                      if (
                        useMonthlyBilling &&
                        !payment.reference?.includes("MON-")
                      ) {
                        // this is a payout from before the monthly payout activation
                        useMonthlyBilling = false;
                      }

                      if (useMonthlyBilling && !enableMonthlyBilling) {
                        continue; // don't do single payout
                      }

                      successWorkSheet.push({
                        Rechnungsnummer: payment.reference,
                        Betrag: payment.amount,
                        Gläubiger:
                          claim.creditor?.businessName ||
                          claim.creditor?.contactName,
                      });
                    }
                  }
                }

                // Convert the array of objects to a worksheet
                const worksheet = utils.json_to_sheet(successWorkSheet);
                const workbook = utils.book_new();

                // Append the worksheet to the workbook
                utils.book_append_sheet(workbook, worksheet, "Claims");

                // Write the file (download it on the browser)
                writeFile(workbook, "claims.xlsx", { bookType: "xlsx" });
              } catch (err) {
                console.error("Error exporting to XLS", err);
              }
            }}
            style={{
              marginTop: 10,
              marginRight: 10,
              backgroundColor: theme.palette.warning.dark,
            }}
            endIcon={<DownloadOutlined />}
          >
            <span
              className="label"
              style={{ fontSize: "0.75rem", color: "white" }}
            >
              Download SEPA file
            </span>
          </Button>
          <FormControlLabel
            label="Incl. online payments"
            value={enableOnlinePayments}
            onChange={(_e, checked) => {
              setEnableOnlinePayments(checked);
            }}
            control={
              <Switch color="secondary" defaultChecked={enableOnlinePayments} />
            }
            labelPlacement="end"
            style={{ marginRight: 10 }}
          />
          <FormControlLabel
            label="Incl. monthly payments"
            value={enableMonthlyBilling}
            onChange={(_e, checked) => {
              setEnableMonthlyBilling(checked);
            }}
            control={
              <Switch color="secondary" defaultChecked={enableMonthlyBilling} />
            }
            labelPlacement="end"
            style={{ marginRight: 10 }}
          />
          <Button
            onClick={async () => {
              if (window.confirm("Do you really want to mark them as paid?")) {
                let successCount = 0;
                for (const claim of claims) {
                  const hasOnlinePayment = claim.payments?.some(
                    (payment: Payment) => {
                      return payment.reference
                        ?.toLowerCase()
                        .includes("online payment");
                    }
                  );
                  let useMonthlyBilling = claim.creditor?.useMonthlyBilling;
                  const paymentInformation =
                    claim.creditor?.paymentInformations.filter(
                      (paymentInformation) =>
                        paymentInformation.currency === "EUR" ||
                        !paymentInformation.currency
                    )[0];

                  if (
                    paymentInformation &&
                    startsWithAnyTwoLetters(paymentInformation.account) &&
                    (claim.currency === "EUR" || !claim.currency) &&
                    (!hasOnlinePayment || enableOnlinePayments)
                  ) {
                    for (const payment of claim.payments.filter(
                      (payment) =>
                        payment.paymentType === "Payout" && !!payment.fileUrl
                    )) {
                      if (
                        useMonthlyBilling &&
                        !payment.reference?.includes("MON-")
                      ) {
                        // this is a payout from before the monthly payout activation
                        useMonthlyBilling = false;
                      }

                      if (useMonthlyBilling && !enableMonthlyBilling) {
                        continue;
                      }

                      if (
                        await dataProvider.update("Payment", {
                          id: payment.id,
                          data: {
                            paymentType: "Payout", // required to trigger the notification
                            isPlanned: false,
                          },
                          previousData: payment,
                        })
                      ) {
                        successCount++;
                      }
                    }
                  }
                }
                notify(
                  `${successCount} Payments marked done`,
                  "success",
                  null,
                  false
                );
              }
            }}
            style={{
              marginTop: 10,
              marginRight: 10,
              backgroundColor: theme.palette.success.dark,
            }}
            endIcon={<MonetizationOnRounded />}
          >
            <span
              className="label"
              style={{ fontSize: "0.75rem", color: "white" }}
            >
              Mark all paid
            </span>
          </Button>
          <Button
            onClick={() => {
              if (foreignCurrencyClaims.length > 0) {
                jsonExport(
                  foreignCurrencyClaims,
                  { rowDelimiter: ";" },
                  (_err, csv) => {
                    // fix encoding
                    const BOM = "\uFEFF";
                    downloadCSV(
                      `${BOM} ${csv}`,
                      "Payments" + new Date()?.toISOString()
                    );
                  }
                );
                setMarkForeignCurrencyPaid(true);
              }
            }}
            style={{
              marginTop: 10,
              marginRight: 10,
              backgroundColor: theme.palette.primary.main,
            }}
            endIcon={<CloudDownloadOutlined />}
          >
            <span
              className="label"
              style={{ fontSize: "0.75rem", color: "white" }}
            >
              Download CSV (FX)
            </span>
          </Button>

          {markForeignCurrencyPaid && (
            <Button
              label="Mark foreign currency claims paid"
              variant="outlined"
              size="large"
              style={{ marginTop: 10, marginLeft: 10 }}
              onClick={async () => {
                if (
                  window.confirm("Do you really want to mark them as paid?")
                ) {
                  let successCount = 0;
                  for (const claim of claims) {
                    const paymentInformation =
                      claim.creditor?.paymentInformations.filter(
                        (paymentInformation) =>
                          paymentInformation.currency === claim.currency ||
                          !paymentInformation.currency
                      )[0];

                    let useMonthlyBilling = claim.creditor?.useMonthlyBilling;
                    const hasOnlinePayment = claim.payments?.some(
                      (payment: Payment) => {
                        return payment.reference
                          ?.toLowerCase()
                          .includes("online payment");
                      }
                    );
                    if (
                      paymentInformation &&
                      claim.currency &&
                      claim.currency !== "EUR" &&
                      (!hasOnlinePayment || enableOnlinePayments)
                    ) {
                      for (const payment of claim.payments?.filter(
                        (payment) => payment.fileUrl
                      )) {
                        if (
                          useMonthlyBilling &&
                          !!payment?.fileUrl &&
                          !payment.reference?.includes("MON-")
                        ) {
                          // this is a payout from before the monthly payout activation
                          useMonthlyBilling = false;
                        }

                        if (useMonthlyBilling && !enableMonthlyBilling) {
                          continue; // don't do single payout
                        }

                        if (
                          await dataProvider.update("Payment", {
                            id: payment.id,
                            data: {
                              isPlanned: false,
                            },
                            previousData: payment,
                          })
                        ) {
                          successCount++;
                        }
                      }
                    }
                  }
                  notify(
                    `${successCount} Payments marked done`,
                    "success",
                    null,
                    false
                  );
                }
              }}
            />
          )}
        </div>
      )}

      <Typography component={"span"} style={{ marginBottom: 25 }} variant="h6">
        Pending bank transfer: {isLoading && <CircularProgress size={12} />}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title & Reference</TableCell>
              <TableCell>Payment Reference</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Creditor</TableCell>
              <TableCell>Payment Info</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Payment Method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claims &&
              claims.length > 0 &&
              claims.map((claim: Claim) => {
                const paymentInformation =
                  claim.creditor?.paymentInformations.filter(
                    (paymentInformation) =>
                      paymentInformation.currency ===
                        (claim.currency || "EUR") ||
                      !paymentInformation.currency
                  )[0];

                let useMonthlyBilling = claim.creditor?.useMonthlyBilling;
                const cleanedReference = claim.reference
                  .replaceAll(" ", "-")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll(":", "")
                  .replaceAll("&", "+")
                  .replaceAll("ß", "ss")
                  .replaceAll("ü", "ue")
                  .replaceAll("ö", "oe")
                  .replaceAll("ä", "ae")
                  .replaceAll("Ü", "Ue")
                  .replaceAll("Ö", "Oe")
                  .replaceAll("Ä", "Ae");

                return claim.payments
                  ?.filter(
                    (payment) =>
                      payment.paymentType === "Payout" &&
                      payment.amount !== 0 &&
                      payment.fileUrl
                  )
                  ?.map((payment: Payment) => {
                    const uniqueId = payment.reference;
                    if (!uniqueIds.has(uniqueId)) {
                      uniqueIds.add(uniqueId);

                      const hasOnlinePayment = claim.payments?.some(
                        (payment: Payment) => {
                          return payment.reference
                            ?.toLowerCase()
                            .includes("online payment");
                        }
                      );
                      if (
                        useMonthlyBilling &&
                        !payment.reference?.includes("MON-")
                      ) {
                        useMonthlyBilling = false;
                      }

                      if (
                        (!hasOnlinePayment || enableOnlinePayments) &&
                        (!useMonthlyBilling || enableMonthlyBilling)
                      ) {
                        if (
                          (!claim.currency || claim.currency === "EUR") &&
                          paymentInformation &&
                          startsWithAnyTwoLetters(paymentInformation.account)
                        ) {
                          const tx = info.createTransaction();

                          tx.creditorName =
                            claim.creditor?.businessName ||
                            claim.creditor?.contactName;
                          tx.creditorIBAN =
                            paymentInformation?.account?.replaceAll(" ", "") ||
                            "";
                          tx.amount = payment.amount;
                          tx.remittanceInfo =
                            useMonthlyBilling && enableMonthlyBilling
                              ? payment.reference
                                  ?.replaceAll("_", "-")
                                  .replace("MON-", "") +
                                " Monatsabrechnung " +
                                format(new Date(payment.paymentDate), "MM.yyyy")
                              : payment.reference?.replaceAll("_", "-") +
                                " - " +
                                cleanedReference +
                                ": " +
                                (claim.title.length > 85
                                  ? claim.title
                                      .replaceAll("_", "-")
                                      .substring(0, 85) + "..."
                                  : claim.title?.replaceAll("_", "-"));
                          tx.end2endId =
                            cleanedReference.substring(0, 14) +
                            "-" +
                            payment.reference
                              ?.replaceAll("_", "-")
                              .substring(0, 14) +
                            "-1";
                          try {
                            info.addTransaction(tx);
                          } catch (e) {
                            console.error("issue in " + claim.reference);
                            throw e;
                          }
                        } else {
                          const foreignCurrencyPaymentInformation =
                            claim.creditor?.paymentInformations.filter(
                              (paymentInformation) =>
                                paymentInformation.currency ===
                                  claim.currency || !paymentInformation.currency
                            )[0];

                          if (foreignCurrencyPaymentInformation?.account) {
                            foreignCurrencyClaims.push({
                              creditorName: claim.creditor?.businessName,
                              creditorIBAN:
                                foreignCurrencyPaymentInformation?.account?.replaceAll(
                                  " ",
                                  ""
                                ) || "",
                              creditorBIC:
                                foreignCurrencyPaymentInformation?.bankIdentifier?.replaceAll(
                                  " ",
                                  ""
                                ) || "",
                              amountInEur: payment.amount,
                              conversionRate: payment.conversionRate,
                              amount: parseFloat(
                                (
                                  payment.amount * payment.conversionRate
                                ).toFixed(2)
                              ),
                              currency: claim.currency,
                              remittanceInfo:
                                payment.reference?.replaceAll("_", "-") +
                                " - " +
                                claim.reference +
                                ": " +
                                (claim.title.length > 85
                                  ? claim.title
                                      .replaceAll("_", "-")
                                      .substring(0, 85) + "..."
                                  : claim.title?.replaceAll("_", "-")),
                              creditorCountry: claim.creditor?.address?.country
                                ? searchMapByKey(
                                    ADDRESS_COUNTRY_SHORT,
                                    claim.creditor?.address
                                      .country as EnumAddressCountry
                                  )
                                : "",
                              creditorAddressLine1:
                                claim.creditor?.address?.addressLine1,
                              creditorAddressLine2:
                                claim.creditor?.address?.addressLine2,
                              creditorCity: claim.creditor?.address?.town,
                              creditorPostalcode:
                                claim.creditor?.address?.postalcode,
                              date: format(payment.paymentDate, "dd.MM.yyyy"),
                            });
                          }
                        }
                      }

                      return (
                        <TableRow key={payment.id}>
                          <TableCell>
                            <a href={"#/Payment/" + payment.id + "/show"}>
                              {claim.title && claim.reference}
                            </a>
                          </TableCell>
                          <TableCell>{payment.reference}</TableCell>
                          <TableCell>
                            {Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(payment.amount || 0)}
                          </TableCell>
                          <TableCell>
                            {format(payment?.createdAt, "dd.MM.yyyy")}
                          </TableCell>
                          <TableCell>{claim.creditor?.businessName}</TableCell>
                          <TableCell>
                            {!paymentInformation ? (
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: theme.palette.error.main,
                                }}
                              >
                                No payment info
                              </span>
                            ) : !startsWithAnyTwoLetters(
                                paymentInformation.account
                              ) ? (
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: theme.palette.warning.main,
                                }}
                              >
                                Non-SEPA bank account
                              </span>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {claim.currency && claim.currency !== "EUR" ? (
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: theme.palette.warning.dark,
                                }}
                              >
                                {claim.currency}
                              </span>
                            ) : (
                              "EUR"
                            )}
                          </TableCell>
                          <TableCell>
                            {!hasOnlinePayment ? (
                              <AccountBalance
                                style={{
                                  height: 20,
                                  marginLeft: 4,
                                  verticalAlign: "middle",
                                  color: theme.palette.success.main,
                                }}
                              />
                            ) : (
                              <CreditCard
                                style={{
                                  height: 20,
                                  marginLeft: 4,
                                  verticalAlign: "middle",
                                  color: theme.palette.info.main,
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  });
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaymentOverview;
