import {
  Assessment,
  CallOutlined,
  FeedbackOutlined,
  Gavel,
  Help,
  HelpOutlineOutlined,
  Info,
  LocalActivityOutlined,
  MailOutline,
  MessageOutlined,
  MoneyOff,
  Replay10Outlined,
  Search,
  SupervisorAccount,
  WhatsApp,
} from "@material-ui/icons";
import { ActivityTypes } from "../../api/activity/Activity";

const activityTypeToIcon: Record<ActivityTypes, React.ReactElement> = {
  [ActivityTypes.OutboundCall]: <CallOutlined style={{ marginRight: 10 }} />,
  [ActivityTypes.OutboundMail]: <MailOutline style={{ marginRight: 10 }} />,
  [ActivityTypes.OutboundLetter]: (
    <LocalActivityOutlined style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.OutboundWhatsapp]: <WhatsApp style={{ marginRight: 10 }} />,
  [ActivityTypes.OutboundSms]: <MessageOutlined style={{ marginRight: 10 }} />,
  [ActivityTypes.Response]: <Replay10Outlined style={{ marginRight: 10 }} />,
  [ActivityTypes.Other]: <HelpOutlineOutlined style={{ marginRight: 10 }} />,
  [ActivityTypes.InternalFeedback]: (
    <FeedbackOutlined style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.CreditorInformation]: <Info style={{ marginRight: 10 }} />,
  [ActivityTypes.CreditorFeedback]: (
    <FeedbackOutlined style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.Research]: <Search style={{ marginRight: 10 }} />,
  [ActivityTypes.JudicialActivity]: <Gavel style={{ marginRight: 10 }} />,
  [ActivityTypes.InsolvencyActivity]: <MoneyOff style={{ marginRight: 10 }} />,
  [ActivityTypes.InternalDecision]: <Assessment style={{ marginRight: 10 }} />,
  [ActivityTypes.CreditorDecision]: (
    <SupervisorAccount style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.RecommendedOutboundMail]: (
    <MailOutline style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.AiCreditorLetterSummary]: (
    <MailOutline style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.AiProposeLetterResponseToDebtorMail]: (
    <MailOutline style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.AiProposePaymentPlan]: (
    <MailOutline style={{ marginRight: 10 }} />
  ),
  [ActivityTypes.RecommendedInternalFeedback]: <FeedbackOutlined />,
};

export const getActivityTypeIcon = (
  activityType: ActivityTypes
): React.ReactElement => {
  return activityTypeToIcon[activityType] || <Help />;
};
