import {
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import * as React from "react";
import { useCallback, useEffect } from "react";
import {
  BooleanField,
  Button,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import {
  getReadableActivityType,
  getReadableResult,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { EnumActivityActivityType } from "../api/activity/EnumActivityActivityType";
import { Claim } from "../api/claim/Claim";
import { ActivityComment } from "../Components/ActivityEventItem";
import { NonInput } from "../Components/NonInputComponent";
import { theme } from "../theme/theme";
import { getAuthToken } from "../util/JsonFilter";

let claimReference: string | null = "";

function handleFileClick(claimReference: string, url: string): void {
  const authHeader = {
    headers: {
      Authorization: getAuthToken(),
    },
  };
  const cleanClaimReference = claimReference?.replaceAll("/", "-");
  let urlToFetch = url.split(cleanClaimReference + "/").pop();
  urlToFetch = encodeURIComponent(urlToFetch || "");

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        cleanClaimReference +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = claimReference + "-" + urlToFetch;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const ActivityFileUrl = (): React.ReactElement => {
  return (
    <ListItem alignItems={"flex-start"} component={"span"}>
      <ListItemText
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
        primary={
          <Typography
            style={{
              width: "25%",
            }}
            variant="caption"
            component={"span"}
          >
            {"File URL"}
          </Typography>
        }
        secondary={
          <>
            <FunctionField
              label="File Url(s)"
              render={(record) => {
                if (record?.fileUrls && record?.fileUrls?.length) {
                  return (
                    <>
                      {record?.fileUrls?.map((fileUrl) => (
                        <span
                          key={fileUrl}
                          style={{
                            display: "block",
                            width: "100%",
                            background: theme.palette.grey[200],
                            marginBottom: "0.5rem",
                            padding: "0.5rem",
                            borderRadius: "4px",
                            color: theme.palette.primary.main,
                            textAlign: "center",
                          }}
                          onClick={() =>
                            handleFileClick(claimReference!, fileUrl)
                          }
                        >
                          {fileUrl}
                        </span>
                      ))}
                      {record?.fileUrl &&
                        record.fileUrl !== null &&
                        claimReference !== null && (
                          <Button
                            variant="outlined"
                            size="medium"
                            component={"span"}
                            startIcon={<ArrowDownward />}
                            style={{ marginLeft: 10 }}
                            onClick={() =>
                              handleFileClick(claimReference!, record.fileUrl!)
                            }
                            label="Download File"
                          />
                        )}
                    </>
                  );
                }
              }}
            />
          </>
        }
      ></ListItemText>
    </ListItem>
  );
};

const ClaimReference = (): React.ReactElement => {
  const record: Claim = useRecordContext();

  useEffect(() => {
    claimReference = record?.reference;
  }, [record?.reference]);

  return <TextField source="reference" />;
};

export const ActivityShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Activity/" + props.id + "/", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NonInput>
          <Grid
            container
            spacing={4}
            className="relativity"
            style={{
              width: "100%",
              padding: "2rem 0rem 3rem",
            }}
          >
            <div className="id-wrapper">
              <Typography component={"span"} variant="caption">
                {"ID: "}
              </Typography>
              <TextField label="ID" source="id" />
            </div>

            <Grid item xs={12} md={7}>
              <List>
                <ListItem alignItems={"flex-start"}>
                  <Typography component={"span"} variant="h6">
                    {"Activity Details:"}
                  </Typography>
                </ListItem>

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Activity Type"}
                      </Typography>
                    }
                    secondary={
                      <FunctionField
                        label="Activity Type"
                        render={(record) => (
                          <Chip
                            key={record.id}
                            label={getReadableActivityType(record.activityType)}
                            size="small"
                            variant="outlined"
                            component={"span"}
                            style={{
                              borderColor: theme.palette.grey[200],
                              background: theme.palette.common.white,
                              borderRadius: 5,
                              textTransform: "capitalize",
                              fontSize: "0.75rem",
                              fontWeight: "bold",
                              color:
                                record.activityType ===
                                EnumActivityActivityType.CreditorFeedback
                                  ? theme.palette.success.dark
                                  : theme.palette.error.dark,
                            }}
                          />
                        )}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Activity Date"}
                      </Typography>
                    }
                    secondary={
                      <DateField
                        label="Activity Date"
                        source="activityDate"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Claim Action"}
                      </Typography>
                    }
                    secondary={
                      <FunctionField
                        label="Claim Action"
                        emptyText="-"
                        render={(record: any) => (
                          <span>
                            {getReadableSubjectByClaimAction(
                              record?.claimAction
                            )}
                          </span>
                        )}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Claim"}
                      </Typography>
                    }
                    secondary={
                      <ReferenceField
                        label="Claim"
                        source="claim.id"
                        reference="Claim"
                        link="show"
                      >
                        <ClaimReference />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Claim Action"}
                      </Typography>
                    }
                    secondary={
                      <FunctionField
                        label="Claim Action"
                        emptyText="-"
                        render={(record: any) => (
                          <span>
                            {getReadableSubjectByClaimAction(
                              record?.claimAction
                            )}
                          </span>
                        )}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Result"}
                      </Typography>
                    }
                    secondary={
                      <FunctionField
                        label="Result"
                        render={(record: any) => (
                          <span
                            style={{
                              color:
                                record.result === "NotReached" ||
                                record.result === "Negative"
                                  ? theme.palette.error.main
                                  : record.result === "Positive"
                                  ? theme.palette.success.main
                                  : theme.palette.primary.main,
                            }}
                          >
                            {getReadableResult(record?.result)}
                          </span>
                        )}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"External ID"}
                      </Typography>
                    }
                    secondary={
                      <TextField label="External ID" source="externalId" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
              </List>
            </Grid>
            <Grid item xs={12} md={5}>
              <List>
                <ListItem alignItems={"flex-start"}>
                  <div style={{ height: 32 }}></div>
                </ListItem>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Manager"}
                      </Typography>
                    }
                    secondary={
                      <ReferenceField
                        link="show"
                        label="Manager"
                        source="manager.id"
                        reference="User"
                        emptyText="(auto)"
                      >
                        <FunctionField
                          label="Manager"
                          render={(record: any) => (
                            <span>{record?.username?.split("@")?.[0]}</span>
                          )}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Is Planned"}
                      </Typography>
                    }
                    secondary={
                      <BooleanField label="Is Planned" source="isPlanned" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Created At"}
                      </Typography>
                    }
                    secondary={
                      <DateField
                        source="createdAt"
                        label="Created At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block" }}
                      >
                        {"Updated At"}
                      </Typography>
                    }
                    secondary={
                      <DateField
                        source="updatedAt"
                        label="Updated At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
              </List>
            </Grid>

            <Grid item xs={12} md={7}>
              <List>
                <ListItem
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography
                        component={"span"}
                        variant="caption"
                        style={{ display: "block", marginBottom: "1rem" }}
                      >
                        {"Comment"}
                      </Typography>
                    }
                    secondary={<ActivityComment />}
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
                <ActivityFileUrl />
              </List>
            </Grid>
          </Grid>
        </NonInput>
      </SimpleShowLayout>
    </Show>
  );
};
