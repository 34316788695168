import { InputJsonValue } from "../types";
export class JsonFilter {
  equals?: InputJsonValue;
  not?: InputJsonValue;
}

export const getAuthToken = () => {
  const data = localStorage.getItem("credentials");
  if (!data) return ""; // Handle null or empty storage
  try {
    return JSON.parse(data).bearerToken ?? data; // Use JSON value if possible, fallback to raw string
  } catch {
    return data; // If parsing fails, assume it's a valid plain string
  }
};
