import { useCallback, useEffect, useState } from "react";
import { useDataProvider, useRecordContext } from "react-admin";
import { Address } from "../../api/address/Address";
import { Claim } from "../../api/claim/Claim";
import { User } from "../../api/user/User";

export const useCreditor = () => {
  const record: Claim = useRecordContext();
  const dataProvider = useDataProvider();
  const [creditor, setCreditor] = useState<User | null>(null);

  const [creditorAddress, setCreditorAddress] = useState<Address | null>(null);

  const fetchData = useCallback(
    async (
      entityName: string,
      id: string,
      setter: React.Dispatch<React.SetStateAction<any>>
    ) => {
      try {
        const { data } = await dataProvider.getOne(entityName, { id });
        setter(data);
      } catch (error) {
        console.log(error);
      }
    },
    [dataProvider]
  );

  useEffect(() => {
    if (record?.creditor?.id) {
      fetchData("User", record.creditor.id, setCreditor);
    }
  }, [record?.creditor?.id, fetchData]);

  useEffect(() => {
    if (creditor?.address?.id) {
      fetchData("Address", creditor.address.id, setCreditorAddress);
    }
  }, [creditor?.address?.id, fetchData]);

  return {
    creditor,
    setCreditor,
    creditorAddress,
    setCreditorAddress,
  };
};
