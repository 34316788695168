import { useCallback } from "react";
import {
  Record,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { normalizePhoneNumber } from "../../api/address/Address";
import { EnumAddressCountry } from "../../api/address/EnumAddressCountry";
import { aircallService } from "../../util/AirCallCalls";
import { usePhone } from "../PhoneContext";

type useAircallProp = {
  userCountry: EnumAddressCountry;
};

export const useAircall = ({ userCountry }: useAircallProp) => {
  const record: Record = useRecordContext();
  const { airCallTab } = usePhone();
  const { identity } = useGetIdentity();
  const notify = useNotify();

  const { data: debtorData } = useGetOne("User", record?.debtor.id || 0, {
    enabled: !!record?.debtor.id,
  });

  const handleCallDebtor = useCallback(() => {
    let debtorPhone = debtorData?.phone;
    if (!debtorPhone) {
      return;
    }
    debtorPhone = normalizePhoneNumber(debtorPhone, userCountry, true);

    aircallService
      .dialNumber(identity?.username, identity?.id, debtorPhone)
      .then((result) => {
        if (result?.json) {
          notify("Dialing Call", "success");
          if (airCallTab && !airCallTab.closed) {
            // The tab is open, switch to it
            airCallTab.focus();
          } else {
            /*
                // The tab is closed or hasn't been opened, open it
                setAirCallTab(window.open("https://phone.aircall.io/", "_blank"));
                */
          }
        } else {
          notify("Could not dial :( Wrong number? Busy?", "error");
        }
      });
  }, [
    debtorData?.phone,
    userCountry,
    identity?.username,
    identity?.id,
    notify,
    airCallTab,
  ]);

  const hidePhone = !debtorData || !debtorData.phone;

  return {
    handleCallDebtor,
    hidePhone,
  };
};
