import * as React from "react";

import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { Grid } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { UserTitle } from "../user/UserTitle";

export const IntegrationEdit = (props: EditProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Integration/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={3}>
            <DateTimeInput label="Last Sync Date" source="lastSyncDate" />
          </Grid>
          <Grid item xs={12} md={9}></Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              source="software"
              label="Software"
              choices={[
                { label: "lexoffice", value: "Lexoffice" },
                { label: "sevDesk", value: "SevDesk" },
                { label: "FastBill", value: "FastBill" },
                { label: "BuchhaltungsButler", value: "BuchhaltungsButler" },
                { label: "Sage", value: "Sage" },
                { label: "Zoho Books", value: "ZohoBooks" },
                { label: "Odoo", value: "Odoo" },
                { label: "Chargebee", value: "Chargebee" },
                { label: "DATEV", value: "Datev" },
                { label: "Billomat", value: "Billomat" },
                { label: "Circuly", value: "Circuly" },
                { label: "Payactive", value: "Payactive" },
                { label: "CopeCart", value: "CopeCart" },
                { label: "Tentary", value: "Tentary" },
                { label: "Billbee", value: "Billbee" },
                { label: "Xentral", value: "Xentral" },
                { label: "Sage", value: "Sage" },
                { label: "easybill", value: "Easybill" },
                { label: "Buhl", value: "Buhl" },
                { label: "Stripe", value: "Stripe" },
                { label: "(Custom)", value: "Custom" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextInput label="Token" source="token" />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <ReferenceInput source="user.id" reference="User" label="User">
              <SelectInput optionText={UserTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="User Identifier" source="userIdentifier" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Organization Identifier"
              source="organizationIdentifier"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="Domain Identifier" source="domainIdentifier" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Database Identifier"
              source="databaseIdentifier"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Reply-To Email Address"
              source="replyToEmailAddress"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Custom Email Address"
              source="customEmailAddress"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Custom Email Selector"
              source="customEmailAddressSelector"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              label="Custom Email Key"
              source="customEmailAddressKey"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="B2C Identifier" source="b2cIdentifier" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput label="B2B Identifier" source="b2bIdentifier" />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={12}>
            <ArrayInput label="Debtor Blacklist" source="debtorBlacklist">
              <SimpleFormIterator>
                <TextInput source="" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>

          <Grid item xs={12} md={4}>
            <NumberInput
              required
              label="Days overdue to fetch (Dunning)"
              source="daysOverDueToFetch"
            />
            <NumberInput
              label="Max days to fetch"
              source="maxDaysOverDueToFetch"
            />
            <NumberInput
              label="Days due to fetch for first reminder"
              source="daysDueToFetchForFirstReminder"
            />
            <NumberInput
              label="Days due to fetch for second reminder"
              source="daysDueToFetchForSecondReminder"
            />
            <NumberInput
              label="Days due to fetch for third reminder"
              source="daysDueToFetchForThirdReminder"
            />
          </Grid>
          <Grid item xs={12} md={8}></Grid>
          <Grid item xs={12}>
            <BooleanInput
              label="Is Custom Email Address Verified"
              source="isCustomEmailAddressVerified"
              style={{ display: "inline" }}
            />
            <BooleanInput
              label="Disable B2B"
              source="disableB2B"
              style={{ display: "inline-block", marginTop: 20, width: "100%" }}
            />
            <BooleanInput
              label="Disable B2C"
              source="disableB2C"
              style={{ display: "inline-block", marginTop: 20, width: "100%" }}
            />
            <BooleanInput
              label="Is Active"
              source="isActive"
              style={{ display: "inline" }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
