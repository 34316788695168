import { Typography } from "@material-ui/core";
import { Close, Explore, Gavel, RepeatRounded } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import React, { useState } from "react";
import {
  Button,
  TopToolbar,
  useGetIdentity,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { EnumActivityClaimAction } from "../api/activity/EnumActivityClaimAction";
import { User } from "../api/user/User";
import { OPS_COURT_MANAGERS } from "../config/AppConfig";
import { theme } from "../theme/theme";
import { getAuthToken } from "../util/JsonFilter";

interface ClaimActivityToolsProps {
  showActivityAdd: boolean;
  onCreateButtonClick: (placeholderText?: string) => void;
  creditor?: {
    disableCourtOrder?: boolean;
    courtOrder?: string;
    courtOrderMinimumB2C?: number;
    courtOrderMinimumB2B?: number;
    disableRegistrationOfficeEnquiry?: boolean;
    vatId?: string;
  };
  debtor?: Partial<User>;
}

export const ClaimActivityTools: React.FC<ClaimActivityToolsProps> = React.memo(
  ({
    showActivityAdd,
    onCreateButtonClick,
    creditor,
    debtor,
  }: ClaimActivityToolsProps) => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const [isLoading, setIsLoading] = useState(false);
    const { identity } = useGetIdentity();
    // this is a maze of if statements - works now, cry later
    const enableCourtHandover =
      OPS_COURT_MANAGERS.includes(identity?.username.toString()) &&
      ["Court", "Precourt"].includes(record?.stage) &&
      (!record?.currency || record?.currency === "EUR") &&
      showActivityAdd &&
      !creditor?.disableCourtOrder &&
      creditor?.courtOrder !== "NO" &&
      ((debtor?.businessType !== "Business" &&
        (!creditor?.courtOrderMinimumB2C ||
          (creditor?.courtOrderMinimumB2C &&
            creditor?.courtOrderMinimumB2C < record?.originalAmountDue))) ||
        (debtor?.businessType === "Business" &&
          debtor?.businessName &&
          debtor?.contactName &&
          (!creditor?.courtOrderMinimumB2B ||
            (creditor?.courtOrderMinimumB2B &&
              creditor?.courtOrderMinimumB2B < record?.originalAmountDue))));

    const enableResearch =
      ["Court", "Precourt"].includes(record?.stage) &&
      showActivityAdd &&
      creditor.vatId &&
      !creditor?.disableRegistrationOfficeEnquiry;

    const enableRecalculation =
      ["Court", "Precourt"].includes(record?.stage) && showActivityAdd;

    async function handleClaimAction(
      action: "PaymentOrder" | "Research" | "Recalculate" = "PaymentOrder",
      claimAction?: EnumActivityClaimAction
    ): Promise<boolean> {
      const authHeader = {
        headers: {
          Authorization: getAuthToken(),
          "Content-Type": "application/json",
        },
      };

      if (
        action === "Recalculate" ||
        window.confirm(action + ": Are you sure? This cannot be undone")
      ) {
        setIsLoading(true);
        return await fetch(
          process.env.REACT_APP_SERVER_URL +
            "/api/claims/" +
            record?.id +
            "/claimAction?action=" +
            action +
            (claimAction ? "&claimAction=" + claimAction : ""),
          {
            method: "POST",
            ...authHeader,
          }
        )
          .then(async (response) => {
            const json = await response.text();
            if (!json || json !== "success") {
              alert(
                json + ". Please add missing data or notify the IT Gang 🥷🏻🥷🏻🥷🏻"
              );
            } else {
              setIsLoading(false);

              refresh(true);
              return true;
            }
            setIsLoading(false);
            return false;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      }
      setIsLoading(false);
      return false;
    }

    const savedComments: string = record?.comment || "";

    return (
      <TopToolbar>
        <div className="topG-toolbar">
          <div className="row">
            <div className="comment-wrapper" style={{
                marginBottom: '1rem',
             }}>
              {savedComments && savedComments.length > 255 ? (
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{
                    width: '100%',
                    overflow: "auto",
                  }}
                >
                  {savedComments.substr(0, 255) + " "}
                  <a
                    style={{ fontSize: 14, color: theme.palette.grey[500] }}
                    href={"/#/Claim/" + record?.id + "/show/4"}
                  >
                    ...more
                  </a>
                </Typography>
              ) : (
                <Typography
                  component={"span"}
                  variant="body2"
                  style={{
                    maxWidth: "50rem",
                    overflow: "auto",
                    whiteSpace: "pre",
                  }}
                >
                  {savedComments}
                </Typography>
              )}
            </div>
            <div className="dog">
              <Button
                size="medium"
                className={`toggle-btn ${showActivityAdd ? "open" : "close"}`}
                href={"/#/Claim/" + record?.id + "/show/2"}
                startIcon={showActivityAdd ? <Close /> : <Add />}
                onClick={() => {
                  onCreateButtonClick(undefined);
                }}
                label={showActivityAdd ? "Cancel" : "New Activity"}
              />
            </div>
          </div>

          <div
            className={`claim-court-buttons ${showActivityAdd ? "show" : ""}`}
          >
            {enableCourtHandover && (
              <Button
                disabled={isLoading}
                size="medium"
                onClick={async () => {
                  if (await handleClaimAction("Recalculate")) {
                    handleClaimAction("PaymentOrder");
                  }
                }}
                startIcon={<Gavel />}
                label="Start Court Process"
              />
            )}
            {enableResearch && (
              <Button
                disabled={isLoading}
                size="medium"
                onClick={() => handleClaimAction("Research")}
                startIcon={<Explore />}
                label="Authority Research"
              />
            )}
            {enableRecalculation && (
              <Button
                disabled={isLoading}
                size="medium"
                onClick={() => handleClaimAction("Recalculate")}
                startIcon={<RepeatRounded />}
                label="Recalculate"
              />
            )}
          </div>
        </div>
      </TopToolbar>
    );
  },
  (prevProps, nextProps) =>
    prevProps.showActivityAdd === nextProps.showActivityAdd &&
    prevProps.creditor === nextProps.creditor &&
    prevProps.debtor === nextProps.debtor
);
