export const stringToHslColor = (
  input: string | number | undefined,
  lightLevel: number
): string => {
  if (!input) {
    return "";
  }

  let hash: number;
  if (typeof input === "string") {
    hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
  } else {
    hash = input;
  }
  const h = hash % 360;
  return "hsl(" + h + ", " + 75 + "%, " + lightLevel + "%)";
};

export const getInitials = (name: string, length = 2): string => {
  if (!name) return "";

  const nameParts = name.trim().split(" ");
  const initials = nameParts
    .slice(0, length)
    .map((part) => part[0].toUpperCase())
    .join("");

  return initials;
};
