import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { ADDRESS_CHOICES } from "../../../address/AddressCreate";

const AccountingFlagForm = ({ isEmployee }): JSX.Element => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        {isEmployee && (
          <>
            <Typography component={"span"} variant="caption">
              Fees
            </Typography>
            <NumberInput
              step={1}
              label="Min. amount B2C"
              source="minimumAmountB2C"
            />
            <NumberInput
              step={1}
              label="Min. amount B2B"
              source="minimumAmountB2B"
            />
            <NumberInput
              step={1}
              label="Max. amount B2C"
              source="maximumAmountB2C"
            />
            <NumberInput
              step={1}
              label="Max. amount B2B"
              source="maximumAmountB2B"
            />
          </>
        )}
        <Typography
          component={"span"}
          variant="caption"
          style={{ display: "block" }}
        >
          VAT
        </Typography>
        <TextInput fullWidth label="VAT Id" source="vatId" />
        <Typography
          component={"span"}
          variant="caption"
          style={{ marginTop: 20, display: "block" }}
        >
          Erfolgspauschale
        </Typography>
        <NumberInput
          step={1}
          label="Erfolgspauschale Ausland %"
          source="successFeeInPercent"
        />
        <ArrayInput
          label="Erfolgspauschale (Custom)"
          source="specificSuccessFeeInPercent"
        >
          <SimpleFormIterator>
            <SelectInput
              source="key"
              label="Country"
              choices={[
                {
                  label: <b>EU</b>,
                  value: "EU",
                },
                {
                  label: <b>Rest of World</b>,
                  value: "ROW",
                },
                ...ADDRESS_CHOICES,
              ]}
              optionText="label"
              optionValue="value"
            />
            <NumberInput source="value" label="%" step={1} />
          </SimpleFormIterator>
        </ArrayInput>
        <Typography component={"span"} variant="caption">
          Billing
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Tooltip title={"Recommended Value"}>
            <Chip
              label={"Monthly"}
              style={{
                padding: 5,
                background: "var(--success)",
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
          <SelectInput
            source="useMonthlyBilling"
            label="Use monthly billing"
            defaultValue={true}
            choices={[
              { id: false, name: "Case-basis" },
              { id: true, name: "Monthly (recommended)" },
            ]}
          />
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Accounting Options
        </Typography>
        <BooleanInput
          label="Keine Negativpauschale"
          source="disableCancellationFee"
          defaultValue={false}
        />
        <BooleanInput
          label="Vorsteuerabzugsberechtigt"
          source="taxDeductionAllowed"
          defaultValue={true}
        />
        {isEmployee && (
          <>
            <BooleanInput
              step={1}
              label="Legal Knowledge"
              source="hasLegalKnowledge"
            />
            <BooleanInput
              step={1}
              label="Language Knowledge"
              source="hasLanguageKnowledge"
            />
          </>
        )}
        <Typography component={"span"} variant="caption">
          Other Services
        </Typography>
        <BooleanInput
          step={1}
          label="Reminder Subscription Active"
          source="hasActiveReminderSubscription"
        />
        <BooleanInput
          step={1}
          label="+ Calling for Reminders"
          source="hasActiveReminderSubscriptionWithCalling"
        />
        <BooleanInput
          step={1}
          label="Credit Rating Subscription Active"
          source="hasActiveCreditRatingSubscription"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Global Options
        </Typography>
        <BooleanInput
          label="Is verified (terms and conditions)"
          source="isVerified"
          defaultValue={false}
        />
      </Grid>
    </Grid>
  );
};

export default AccountingFlagForm;
