import { Tooltip } from "@material-ui/core";
import * as React from "react";
import { memo, useCallback, useEffect } from "react";
import {
  Show,
  ShowProps,
  useGetIdentity,
  useGetMany,
  useRecordContext,
} from "react-admin";
import { ClaimHeaderBar } from "../Components/ClaimHeaderBar";
import { ClaimTabs } from "../Components/tabs/claimShow/ClaimTabs";
import { useClaimShowLogic } from "../context/hook/useClaimShowLogic";
import { useSocket } from "../context/hook/useSocket";
import { getInitials } from "../util/StyleUtils";

const ActiveUsers = memo(({ claimId }: { claimId: string }) => {
  const [activeUsers, setActiveUsers] = React.useState<string[]>([]);
  const { socket, isConnected } = useSocket();
  const { identity } = useGetIdentity();

  // Fetch user details for active editors with debounced updates
  const { data: activeUserData } = useGetMany("User", activeUsers, {
    enabled: activeUsers.length > 0,
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isConnected && socket && claimId && identity?.id) {
      // Async join operation
      Promise.resolve().then(() => {
        socket.emit("joinClaim", claimId);
        socket.emit("startEditing", claimId);
      });

      const handleActiveEditorsUpdate = (users: string[]) => {
        // Debounce updates to prevent rapid re-renders
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setActiveUsers(users);
        }, 300);
      };

      socket.on("activeEditorsUpdate", handleActiveEditorsUpdate);

      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        socket.off("activeEditorsUpdate");

        // Async cleanup
        Promise.resolve().then(() => {
          if (socket.connected) {
            socket.emit("stopEditing", claimId);
          }
        });
      };
    }
  }, [isConnected, socket, claimId, identity?.id]);

  if (!activeUsers.length || !activeUserData?.length) return null;

  return (
    <div className="item-wrapper">
      {activeUserData.map((user) => {
        if (user.id === identity?.id) return null;
        return (
          <Tooltip
            key={user.id}
            title={`${user.name} is working on this claim`}
          >
            <div className="active-user">{getInitials(user.name)}</div>
          </Tooltip>
        );
      })}
    </div>
  );
});

export const ClaimShow = (props: ShowProps): React.ReactElement => {
  const { aside } = useClaimShowLogic(props.id);
  const claimId = props.id?.toString();

  // Handle keypress for navigation
  const handleKeyPress = useCallback(
    (event) => {
      if (
        event.target === document.body &&
        (event.key === "e" || event.key === "x")
      ) {
        window.open(`/#/Claim/${props?.id}/`, "_self");
      }
    },
    [props?.id]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props} title="Claim" hasEdit={false} aside={aside}>
      <div style={{ width: "100%" }}>
        {claimId && <ActiveUsers claimId={claimId} />}
        <ClaimShowContent />
      </div>
    </Show>
  );
};

const ClaimShowContent = memo(() => {
  const recordContext = useRecordContext();
  const claimId = recordContext?.id?.toString();

  if (!claimId) return null;

  return (
    <>
      <ClaimHeaderBar claimId={claimId} />
      <ClaimTabs claimId={claimId} />
    </>
  );
});
