import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CalendarTodayRounded,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator } from "react-admin";
import { EnumPaymentPaymentType } from "../../../api/payment/EnumPaymentPaymentType";
import { theme } from "../../../theme/theme";
import { numberFormatEUR } from "../../../util/CurrencyUtil";

type AdditionalCostCardProps = {
  payments: any;
  paymentDataLoaded: any;
  record: any;
  creditor: any;
  creditorAddress: any;
};

const AdditionalCostCard = ({
  payments,
  paymentDataLoaded,
  record,
  creditor,
  creditorAddress,
}: AdditionalCostCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (paymentDataLoaded) {
      setIsLoading(false);
    }
  }, [paymentDataLoaded]);

  const thirdPartyOutboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorOutboundPosition
  );

  const thirdPartyInboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorInboundPosition
  );

  const thirdPartyInboundPaidPayments = thirdPartyInboundPayments.filter(
    (payment) => payment.isPlanned === false
  );

  let thirdPartyInboundPaidPaymentsTotal = parseFloat(
    Math.abs(
      thirdPartyInboundPaidPayments.reduce(function (a, b) {
        if (
          creditorAddress?.country === "DEU" &&
          creditor.taxDeductionAllowed &&
          !b.isTaxFree
        ) {
          return a + parseFloat((b.amount * 1.19).toFixed(2));
        }
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  const thirdPartyPaymentsTotal = parseFloat(
    (
      parseFloat(
        Math.abs(
          thirdPartyOutboundPayments.reduce(function (a, b) {
            return a + (b.isTaxFree ? b.amount : b.amount / 1.19);
          }, 0)
        )?.toFixed(2)
      ) - thirdPartyInboundPaidPaymentsTotal
    )?.toFixed(2)
  );

  if (isLoading || !record || !creditor || !payments || !paymentDataLoaded) {
    return <LoadingIndicator />;
  }

  return (
    <Card>
      <CardContent style={{ position: "relative" }}>
        {creditor.useMonthlyBilling && (
          <span
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              background: "var(--primary)",
              padding: "1rem 0.75rem",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              component={"span"}
              variant="subtitle2"
              style={{ fontWeight: "bold" }}
            >
              {" Monthly"}
              <CalendarTodayRounded
                style={{ width: 16, height: 12, marginLeft: "0.5rem" }}
              />
            </Typography>
          </span>
        )}
        <Typography
          component={"span"}
          variant="h6"
          style={{
            display: "block",
            marginBottom: "0.5rem",
          }}
        >
          Nebenkosten
        </Typography>
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorOutboundPosition"
          }
          startIcon={<CloudDownloadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[600],
            fontSize: 12,
            marginTop: 10,
            marginBottom: 10,
            minWidth: "100%",
          }}
          size={"medium"}
          label="Eingehende Rechnung"
          variant="contained"
        />
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorInboundPosition"
          }
          startIcon={<CloudUploadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[800],
            fontSize: 12,
            minWidth: "100%",
            marginBottom: 20,
          }}
          size={"medium"}
          label="Ausgehende Rechnung"
          variant="contained"
        />
        {thirdPartyOutboundPayments?.length > 0 && (
          <List
            subheader={
              <Typography component={"span"} variant="body2">
                Eingehende Rechnungen:
              </Typography>
            }
          >
            {thirdPartyOutboundPayments?.map((payment, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip title={payment.reference}>
                          <Typography
                            component={"span"}
                            variant={"body2"}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                `/#/Payment/${payment.id}`,
                                "_blank",
                                "noopener, noreferrer"
                              );
                            }}
                          >
                            {payment.reference.length > 20
                              ? `${payment.reference.slice(0, 20)}...`
                              : payment.reference}
                          </Typography>
                        </Tooltip>

                        {payment.isPlanned && (
                          <div
                            style={{
                              color: theme.palette.error.main,
                              height: 20,
                            }}
                          >
                            <CheckBoxOutlineBlank
                              style={{ height: 20, verticalAlign: "middle" }}
                            />
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {"Unbezahlt"}
                            </span>
                          </div>
                        )}
                      </div>
                    }
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography component={"span"} variant={"subtitle2"}>
                          {format(new Date(payment.paymentDate), "dd.MM.yyyy")}
                        </Typography>
                        <Typography component={"span"} variant={"subtitle2"}>
                          {numberFormatEUR.format(Math.abs(payment.amount))}
                        </Typography>
                      </div>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
        {thirdPartyInboundPayments?.length > 0 && (
          <List
            subheader={
              <Typography component={"span"} variant="body2">
                Ausgehende Rechnungen:
              </Typography>
            }
          >
            {thirdPartyInboundPayments?.map((payment, index) => {
              let amount = payment.amount;
              if (
                creditorAddress?.country === "DEU" &&
                creditor?.taxDeductionAllowed &&
                !payment?.isTaxFree
              ) {
                amount = parseFloat((amount * 1.19)?.toFixed(2));
              }

              const formattedUpdateDate = payment?.updatedAt
                ? format(new Date(payment.updatedAt), "dd.MM.yyyy")
                : "";

              const formattedPaymentDate = payment?.paymentDate
                ? format(new Date(payment.paymentDate), "dd.MM.yyyy")
                : "";

              return (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              color: payment?.isPlanned
                                ? theme.palette.error.main
                                : theme.palette.success.main,
                              height: 20,
                              display: "flex",
                              marginTop: 5,
                              marginBottom: 5,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: 12, fontWeight: 600 }}>
                              {payment?.isPlanned
                                ? "Unbezahlt"
                                : `Bezahlt ${formattedUpdateDate}`}
                            </span>
                            {payment?.isPlanned ? (
                              <CheckBoxOutlineBlank
                                style={{ height: 20, verticalAlign: "middle" }}
                              />
                            ) : (
                              <CheckBoxOutlined
                                style={{ height: 20, verticalAlign: "middle" }}
                              />
                            )}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Tooltip title={payment?.reference}>
                              <Typography
                                component={"span"}
                                variant={"body2"}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(
                                    `/#/Payment/${payment.id}`,
                                    "_blank",
                                    "noopener, noreferrer"
                                  );
                                }}
                              >
                                {payment?.reference.length > 20
                                  ? `${payment?.reference.slice(0, 20)}...`
                                  : payment?.reference}
                              </Typography>
                            </Tooltip>
                          </div>
                        </div>
                      }
                      secondary={
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography component={"span"} variant={"caption"}>
                            {formattedPaymentDate}
                          </Typography>
                          <Typography component={"span"} variant={"caption"} style={{fontWeight: 600}}>
                            {numberFormatEUR.format(Math.abs(amount || 0))}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              );
            })}
          </List>
        )}

        {thirdPartyPaymentsTotal > 0 && (
          <Typography
            component={"span"}
            variant="body2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 10,
              fontWeight: 600,
              color: theme.palette.error.main,
            }}
          >
            <span>
              Offen netto:
            </span>
            
            <span>
              {numberFormatEUR.format(thirdPartyPaymentsTotal)}
            </span>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AdditionalCostCard;
