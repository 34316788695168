import { Grid } from "@material-ui/core";
import qs from "qs";
import * as React from "react";
import {
  AutocompleteInput,
  Button,
  Create,
  CreateProps,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { ADDRESS_COUNTRY_READABLE } from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { UserTitle } from "../user/UserTitle";

export const ADDRESS_CHOICES = [
  {
    label: ADDRESS_COUNTRY_READABLE.get("DEU" as EnumAddressCountry),
    value: "DEU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("AUT" as EnumAddressCountry),
    value: "AUT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CHE" as EnumAddressCountry),
    value: "CHE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("FRA" as EnumAddressCountry),
    value: "FRA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GBR" as EnumAddressCountry),
    value: "GBR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ITA" as EnumAddressCountry),
    value: "ITA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("AUS" as EnumAddressCountry),
    value: "AUS",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("AFG" as EnumAddressCountry),
    value: "AFG",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("AGO" as EnumAddressCountry),
    value: "AGO",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ALB" as EnumAddressCountry),
    value: "ALB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ARE" as EnumAddressCountry),
    value: "ARE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ARG" as EnumAddressCountry),
    value: "ARG",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ARM" as EnumAddressCountry),
    value: "ARM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("AZE" as EnumAddressCountry),
    value: "AZE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ETH" as EnumAddressCountry),
    value: "ETH",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BGD" as EnumAddressCountry),
    value: "BGD",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BEL" as EnumAddressCountry),
    value: "BEL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BEN" as EnumAddressCountry),
    value: "BEN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BGR" as EnumAddressCountry),
    value: "BGR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BIH" as EnumAddressCountry),
    value: "BIH",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BLR" as EnumAddressCountry),
    value: "BLR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BHR" as EnumAddressCountry),
    value: "BHR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BRA" as EnumAddressCountry),
    value: "BRA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BFA" as EnumAddressCountry),
    value: "BFA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BDI" as EnumAddressCountry),
    value: "BDI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BMU" as EnumAddressCountry),
    value: "BMU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("BOL" as EnumAddressCountry),
    value: "BOL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CHL" as EnumAddressCountry),
    value: "CHL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CHN" as EnumAddressCountry),
    value: "CHN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CZE" as EnumAddressCountry),
    value: "CZE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("COM" as EnumAddressCountry),
    value: "COM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CRI" as EnumAddressCountry),
    value: "CRI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CUB" as EnumAddressCountry),
    value: "CUB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("DNK" as EnumAddressCountry),
    value: "DNK",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("DOM" as EnumAddressCountry),
    value: "DOM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("DZA" as EnumAddressCountry),
    value: "DZA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("DJI" as EnumAddressCountry),
    value: "DJI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CIV" as EnumAddressCountry),
    value: "CIV",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ECU" as EnumAddressCountry),
    value: "ECU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("EGY" as EnumAddressCountry),
    value: "EGY",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ERI" as EnumAddressCountry),
    value: "ERI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ESP" as EnumAddressCountry),
    value: "ESP",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("EST" as EnumAddressCountry),
    value: "EST",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SWZ" as EnumAddressCountry),
    value: "SWZ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("FIN" as EnumAddressCountry),
    value: "FIN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GEO" as EnumAddressCountry),
    value: "GEO",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GHA" as EnumAddressCountry),
    value: "GHA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GRC" as EnumAddressCountry),
    value: "GRC",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GAB" as EnumAddressCountry),
    value: "GAB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GMB" as EnumAddressCountry),
    value: "GMB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GIN" as EnumAddressCountry),
    value: "GIN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GNQ" as EnumAddressCountry),
    value: "GNQ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GTM" as EnumAddressCountry),
    value: "GTM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("GLP" as EnumAddressCountry),
    value: "GLP",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("HTI" as EnumAddressCountry),
    value: "HTI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("HKG" as EnumAddressCountry),
    value: "HKG",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("HND" as EnumAddressCountry),
    value: "HND",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("HRV" as EnumAddressCountry),
    value: "HRV",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("HUN" as EnumAddressCountry),
    value: "HUN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("IDN" as EnumAddressCountry),
    value: "IDN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("IND" as EnumAddressCountry),
    value: "IND",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("IRL" as EnumAddressCountry),
    value: "IRL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("IRN" as EnumAddressCountry),
    value: "IRN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("IRQ" as EnumAddressCountry),
    value: "IRQ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ISR" as EnumAddressCountry),
    value: "ISR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("JAM" as EnumAddressCountry),
    value: "JAM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("JEY" as EnumAddressCountry),
    value: "JEY",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("JOR" as EnumAddressCountry),
    value: "JOR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("JPN" as EnumAddressCountry),
    value: "JPN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CAN" as EnumAddressCountry),
    value: "CAN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KAZ" as EnumAddressCountry),
    value: "KAZ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CYM" as EnumAddressCountry),
    value: "CYM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KHM" as EnumAddressCountry),
    value: "KHM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CPV" as EnumAddressCountry),
    value: "CPV",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CMR" as EnumAddressCountry),
    value: "CMR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("COL" as EnumAddressCountry),
    value: "COL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KEN" as EnumAddressCountry),
    value: "KEN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KGZ" as EnumAddressCountry),
    value: "KGZ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KOR" as EnumAddressCountry),
    value: "KOR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("COD" as EnumAddressCountry),
    value: "COD",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("KWT" as EnumAddressCountry),
    value: "KWT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LBN" as EnumAddressCountry),
    value: "LBN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LTU" as EnumAddressCountry),
    value: "LTU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LIE" as EnumAddressCountry),
    value: "LIE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LKA" as EnumAddressCountry),
    value: "LKA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LUX" as EnumAddressCountry),
    value: "LUX",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LVA" as EnumAddressCountry),
    value: "LVA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LSO" as EnumAddressCountry),
    value: "LSO",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("LBR" as EnumAddressCountry),
    value: "LBR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MAR" as EnumAddressCountry),
    value: "MAR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MDA" as EnumAddressCountry),
    value: "MDA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MDG" as EnumAddressCountry),
    value: "MDG",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MWI" as EnumAddressCountry),
    value: "MWI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MLI" as EnumAddressCountry),
    value: "MLI",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MNG" as EnumAddressCountry),
    value: "MNG",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MOZ" as EnumAddressCountry),
    value: "MOZ",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MEX" as EnumAddressCountry),
    value: "MEX",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MKD" as EnumAddressCountry),
    value: "MKD",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MLT" as EnumAddressCountry),
    value: "MLT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MNE" as EnumAddressCountry),
    value: "MNE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("MYS" as EnumAddressCountry),
    value: "MYS",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NAM" as EnumAddressCountry),
    value: "NAM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NGA" as EnumAddressCountry),
    value: "NGA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NER" as EnumAddressCountry),
    value: "NER",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NLD" as EnumAddressCountry),
    value: "NLD",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NOR" as EnumAddressCountry),
    value: "NOR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NIC" as EnumAddressCountry),
    value: "NIC",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("NZL" as EnumAddressCountry),
    value: "NZL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("OMN" as EnumAddressCountry),
    value: "OMN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PAN" as EnumAddressCountry),
    value: "PAN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PRY" as EnumAddressCountry),
    value: "PRY",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PAK" as EnumAddressCountry),
    value: "PAK",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PER" as EnumAddressCountry),
    value: "PER",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PHL" as EnumAddressCountry),
    value: "PHL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("POL" as EnumAddressCountry),
    value: "POL",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("PRT" as EnumAddressCountry),
    value: "PRT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("QAT" as EnumAddressCountry),
    value: "QAT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ROU" as EnumAddressCountry),
    value: "ROU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("RUA" as EnumAddressCountry),
    value: "RUA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("RUS" as EnumAddressCountry),
    value: "RUS",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("RWA" as EnumAddressCountry),
    value: "RWA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SAU" as EnumAddressCountry),
    value: "SAU",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SEN" as EnumAddressCountry),
    value: "SEN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SGP" as EnumAddressCountry),
    value: "SGP",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SRB" as EnumAddressCountry),
    value: "SRB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SYC" as EnumAddressCountry),
    value: "SYC",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SLE" as EnumAddressCountry),
    value: "SLE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SLV" as EnumAddressCountry),
    value: "SLV",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SVK" as EnumAddressCountry),
    value: "SVK",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SVN" as EnumAddressCountry),
    value: "SVN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SWE" as EnumAddressCountry),
    value: "SWE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SOM" as EnumAddressCountry),
    value: "SOM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("STP" as EnumAddressCountry),
    value: "STP",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SDN" as EnumAddressCountry),
    value: "SDN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SSD" as EnumAddressCountry),
    value: "SSD",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ZMB" as EnumAddressCountry),
    value: "ZMB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("SYR" as EnumAddressCountry),
    value: "SYR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("THA" as EnumAddressCountry),
    value: "THA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TJK" as EnumAddressCountry),
    value: "TJK",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TKM" as EnumAddressCountry),
    value: "TKM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TZA" as EnumAddressCountry),
    value: "TZA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TGO" as EnumAddressCountry),
    value: "TGO",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TUN" as EnumAddressCountry),
    value: "TUN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TUR" as EnumAddressCountry),
    value: "TUR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("TWN" as EnumAddressCountry),
    value: "TWN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("UGA" as EnumAddressCountry),
    value: "UGA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("UKR" as EnumAddressCountry),
    value: "UKR",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("URY" as EnumAddressCountry),
    value: "URY",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("USA" as EnumAddressCountry),
    value: "USA",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("UZB" as EnumAddressCountry),
    value: "UZB",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("VCT" as EnumAddressCountry),
    value: "VCT",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("VEN" as EnumAddressCountry),
    value: "VEN",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("VNM" as EnumAddressCountry),
    value: "VNM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("YEM" as EnumAddressCountry),
    value: "YEM",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ZAF" as EnumAddressCountry),
    value: "ZAF",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("ZWE" as EnumAddressCountry),
    value: "ZWE",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CAF" as EnumAddressCountry),
    value: "CAF",
  },
  {
    label: ADDRESS_COUNTRY_READABLE.get("CYP" as EnumAddressCountry),
    value: "CYP",
  },
];

export const AddressCreate = (props: CreateProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const notify = useNotify();

  return (
    <Create
      {...props}
      onSuccess={() => {
        // previous page: userCreate
        if (parsedLocation.userId) {
          if (
            parsedLocation.isWizard &&
            (!parsedLocation.userType || parsedLocation.userType === "Default")
          ) {
            notify("Address created. Add payment information now.", "success");
            history.push(
              "/PaymentInformation/create?userId=" +
                parsedLocation.userId +
                "&isWizard=true"
            );
          } else {
            notify("Address created.", "success");
            history.push("/User/" + parsedLocation.userId + "/show");
          }
        } else {
          history.go(-1);
        }
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar style={{ justifyContent: "space-between" }}>
            <SaveButton label="Create" />
            {parsedLocation.isWizard && (
              <Button
                label="Skip"
                onClick={() => {
                  history.go(-2);
                }}
              />
            )}
          </Toolbar>
        }
      >
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <ReferenceInput
              perPage={5}
              defaultValue={parsedLocation.userId}
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
              })}
              source="user.id"
              reference="User"
              label="User"
              isRequired
            >
              <AutocompleteInput
                isRequired
                suggestionLimit={5}
                optionText={UserTitle}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={6} className="mobile-hide"></Grid>
          <Grid item xs={12} md={6}>
            <AddressLine1Input />
          </Grid>
          <Grid item xs={12} md={6}>
            <AddressLine2Input />
          </Grid>

          <Grid item xs={12} md={6}>
            <PostalCodeAndTownInput />
          </Grid>

          <Grid item xs={12} md={6}>
            <AutocompleteInput
              source="country"
              label="Country"
              choices={ADDRESS_CHOICES}
              defaultValue={"DEU"}
              optionText="label"
              optionValue="value"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectInput
              source="verificationStatus"
              label="Verification Status"
              choices={[
                { label: "VALID", value: "VALID" },
                { label: "INVALID", value: "INVALID" },
                { label: "UNKNOWN", value: "UNKNOWN" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LanguageInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const LanguageInput = () => {
  return (
    <SelectInput
      required
      source="languageCountry"
      label="Language"
      defaultValue={"DEU"}
      choices={[
        {
          label: "Deutsch 🇩🇪",
          value: "DEU",
        },
        {
          label: "Français 🇫🇷",
          value: "FRA",
        },
        {
          label: "English 🇬🇧",
          value: "GBR",
        },
        {
          label: "Italian 🇮🇹",
          value: "ITA",
        },
        {
          label: "Japanese 🇯🇵",
          value: "JPN",
        },
      ]}
      optionText="label"
      optionValue="value"
    />
  );
};

const TownInput = () => {
  const { values } = useFormState();

  return (
    <TextInput
      required
      style={{ width: "55%" }}
      label="Town"
      source="town"
      onBlur={(e) => {
        values.town = e.target.value.trim();
      }}
    />
  );
};

export interface ParsedAddress {
  addressline1: string;
  zip: string;
  city: string;
  country?: string;
}

export function parseAddress(address: string): ParsedAddress | null {
  // Regular expression to capture German address patterns with optional country
  const addressPattern =
    /^(.*?)[,\s]+(\d{4,5})[,\s]+(.*?)(?:[,\s]+([A-Za-z\s]+))?$/;

  const match = address.match(addressPattern);

  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, addressline1, zip, city, country] = match;
    return {
      addressline1: addressline1.trim(),
      zip: zip.trim(),
      city: city.trim(),
      country: country ? country.trim() : undefined,
    };
  }

  return null;
}

export const AddressLine1Input = () => {
  const form = useForm();
  const notify = useNotify();

  return (
    <TextInput
      required
      label="Address Line 1"
      source="addressLine1"
      style={{ minWidth: "50%" }}
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        if (pasteContent) {
          let parsedInput: ParsedAddress | null;
          try {
            parsedInput = parseAddress(pasteContent);
          } catch (e) {}

          if (
            parsedInput &&
            parsedInput.zip &&
            parsedInput.addressline1 &&
            parsedInput.city
          ) {
            form.change("addressLine1", parsedInput.addressline1);
            form.change("town", parsedInput.city);
            form.change("postalcode", parsedInput.zip);
            notify("Address detected, check country!", "info", null, false);
          } else {
            form.change("addressLine1", pasteContent);
          }
        }
      }}
    />
  );
};

export const AddressLine2Input = () => {
  const form = useForm();
  const notify = useNotify();

  return (
    <TextInput
      label="Address Line 2"
      source="addressLine2"
      style={{ minWidth: "50%" }}
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        if (pasteContent) {
          let parsedInput: ParsedAddress | null;
          try {
            parsedInput = parseAddress(pasteContent);
          } catch (e) {}

          if (
            parsedInput &&
            parsedInput.zip &&
            parsedInput.addressline1 &&
            parsedInput.city
          ) {
            form.change("addressLine1", parsedInput.addressline1);
            form.change("town", parsedInput.city);
            form.change("postalcode", parsedInput.zip);
            notify("Address detected, check country!", "info", null, false);
          } else {
            form.change("addressLine2", pasteContent);
          }
        }
      }}
    />
  );
};

export const PostalCodeAndTownInput = () => {
  const { values } = useFormState();

  return (
    <div>
      <TextInput
        required
        style={{ width: "40%", marginRight: "5%" }}
        label="Postalcode"
        source="postalcode"
        onBlur={(e) => {
          values.postalcode = e.target.value.trim();
        }}
      />

      <TownInput />
    </div>
  );
};
