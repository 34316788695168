import { Typography } from "@material-ui/core";
import { TextInput } from "react-admin";
import BasicInfoForm from "./steps/BasicInfoForm";

interface AdditionalContactFormProps {
  isLaywerUser: boolean;
  isInsolvencyAdministrator: boolean;
  isPartner: boolean;
  onValidationChange: (isValid: boolean) => void;
}

const AdditionalContactForm = ({
  isLaywerUser,
  isInsolvencyAdministrator,
  isPartner,
  onValidationChange,
}: AdditionalContactFormProps) => {
  return (
    <>
      <BasicInfoForm
        isAdditionalContact={true}
        isLaywerUser={isLaywerUser}
        isPartner={isPartner}
        isInsolvencyAdministrator={isInsolvencyAdministrator}
        onValidationChange={onValidationChange}
      />
      <Typography component={"span"} variant="caption">
        Comments
      </Typography>
      <TextInput
        label="Comment"
        multiline
        fullWidth
        minRows={6}
        source="comment"
      />
    </>
  );
};

export default AdditionalContactForm;
