import { gql } from "@apollo/client/core";
import { AuthProvider } from "react-admin";
import {
  listenToExpiration,
  removeCredentials,
  removeExpirationListener,
  setCredentials,
} from "../auth";
import { USER_DATA_LOCAL_STORAGE_ITEM } from "../constants";
import { apolloClient } from "../data-provider/graphqlDataProvider";
import { Credentials, LoginMutateResult } from "../types";

let expirationListener: ((minutes: number) => void) | null = null;

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(credentials: { username: $username, password: $password }) {
      id
      username
      accessToken
      name
    }
  }
`;

export const jwtAuthProvider: AuthProvider = {
  login: async (credentials: Credentials) => {
    const userData = await apolloClient.mutate<LoginMutateResult>({
      mutation: LOGIN,
      variables: {
        username: credentials.username,
        password: credentials.password,
      },
    });
    if (userData && userData.data?.login.username) {
      const bearerToken = createBearerAuthorizationHeader(
        userData.data.login?.accessToken
      );
      setCredentials({
        username: userData.data.login.username,
        password: credentials.password,
        bearerToken,
        notify: credentials.notify,
      });
      localStorage.setItem(
        USER_DATA_LOCAL_STORAGE_ITEM,
        JSON.stringify(userData.data)
      );
      // Set up expiration warning
      if (credentials.notify) {
        expirationListener = (minutes: number) => {
          credentials.notify(
            "warning",
            "You will be logged out in " + minutes + " minutes automatically",
            { autoHideDuration: minutes * 60 * 1000 }
          );
        };
        listenToExpiration(expirationListener);
      }
      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    if (expirationListener) {
      removeExpirationListener(expirationListener);
      expirationListener = null;
    }
    removeCredentials();
    return Promise.resolve();
  },
  checkError: ({ status, body }) => {
    const graphqlStatus =
      body?.graphQLErrors?.[0]?.extensions?.response?.statusCode;
    const graphqlErrorStatus =
      body?.graphQLErrors?.[0]?.extensions?.originalError?.statusCode;

    if (
      status === 401 ||
      status === 403 ||
      graphqlErrorStatus === 401 ||
      graphqlStatus === 401
    ) {
      removeCredentials();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: (params?: { notify?: Credentials["notify"] }) => {
    const credentials = localStorage.getItem(USER_DATA_LOCAL_STORAGE_ITEM);
    if (!credentials) {
      return Promise.reject();
    }

    // If notify is provided and we don't have an expiration listener yet
    if (params?.notify && !expirationListener) {
      expirationListener = (minutes: number) => {
        params.notify(
          "warning",
          "You will be logged out in " + minutes + " minutes automatically",
          { autoHideDuration: minutes * 60 * 1000 }
        );
      };
      listenToExpiration(expirationListener);
    }

    return Promise.resolve();
  },
  getPermissions: () => Promise.reject("Unknown method"),
  getIdentity: () => {
    const str = localStorage.getItem(USER_DATA_LOCAL_STORAGE_ITEM);
    const userData: LoginMutateResult = JSON.parse(str || "");

    return Promise.resolve({
      id: userData.login.id,
      username: userData.login.username,
      fullName: userData.login.name || userData.login.username,
      avatar:
        "https://debtist2023.sharepoint.com/_layouts/15/userphoto.aspx?size=L&username=" +
        userData.login.username,
    });
  },
};

export function createBearerAuthorizationHeader(accessToken: string) {
  return `Bearer ${accessToken}`;
}
