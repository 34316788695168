import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Record,
  useDataProvider,
  useGetList,
  useRecordContext,
} from "react-admin";
import { Activity } from "../../api/activity/Activity";
import { Payment } from "../../api/payment/Payment";
import { PaymentPlan } from "../../api/paymentPlan/PaymentPlan";
import { getStatusToShow } from "../../util/GetStatus";

export const useClaimPaymentData = (claimId: string) => {
  const record: Record = useRecordContext();
  const dataProvider = useDataProvider();
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([]);

  const { data: paymentData } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    { claim: { id: record?.id || 0 } },
    { enabled: !!record?.id }
  );

  const { data: activityData } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 25 },
    { field: "activityDate", order: "DESC" },
    {
      claim: { id: record?.id || 0 },
    },
    { enabled: !!record?.id }
  );

  const statusToShow = useMemo(() => {
    return getStatusToShow(
      record,
      Object.values(activityData || {}) as Activity[],
      paymentPlans || [],
      Object.values(paymentData || {}) as Payment[]
    );
  }, [record, activityData, paymentPlans, paymentData]);

  const fetchPaymentPlans = useCallback(async () => {
    if (record?.paymentPlans && record.paymentPlans.length > 0) {
      const { data } = await dataProvider.getMany("PaymentPlan", {
        ids: record.paymentPlans.map((paymentPlan: any) => paymentPlan.id),
      });
      setPaymentPlans(data as PaymentPlan[]);
    } else {
      setPaymentPlans([]);
    }
  }, [dataProvider, record?.paymentPlans]);

  useEffect(() => {
    if (record?.id) {
      fetchPaymentPlans();
    }
  }, [fetchPaymentPlans, record?.id]);

  if (!claimId) {
    return {
      paymentData: null,
      statusToShow: null,
      paymentPlans: [],
      activityData: [],
    };
  }

  return {
    paymentData,
    statusToShow,
    paymentPlans,
    activityData,
  };
};
