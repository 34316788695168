import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Record } from "react-admin";
import { useForm } from "react-final-form";

interface FileManagerProps {
  source: string;
  record: Record;
  currentActivityFileUrls: string[];
}

const FileManager: React.FC<FileManagerProps> = ({
  source,
  record,
  currentActivityFileUrls,
}) => {
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [availableFileUrls, setAvailableFileUrls] = useState<string[]>([]);
  const form = useForm();

  useEffect(() => {
    let urls = record?.fileUrls || currentActivityFileUrls;

    // Check if urls is a string and parse it
    if (typeof urls === "string") {
      try {
        urls = JSON.parse(urls);
      } catch (e) {
        console.error("Error parsing fileUrls:", e);
        urls = [];
      }
    }

    // Ensure urls is an array
    if (Array.isArray(urls)) {
      const matchingUrls = [];
      const nonMatchingUrls = [];

      const conditions = (url: string) =>
        !url.toLowerCase().includes("dunning") &&
        !url.toLowerCase().includes("courtprocessintro") &&
        !url.toLowerCase().includes("monitoring") &&
        !url.toLowerCase().includes("paymentplan");

      urls.forEach((url: string) => {
        if (conditions(url)) {
          nonMatchingUrls.push(url);
        } else {
          matchingUrls.push(url);
        }
      });

      setAvailableFileUrls([...matchingUrls, ...nonMatchingUrls]);
      setFileUrls(
        currentActivityFileUrls.length > 0 ? currentActivityFileUrls : []
      ); // Set the initial selected file URLs
    } else {
      console.error("fileUrls is not an array:", urls);
    }
  }, [record?.fileUrls, currentActivityFileUrls]);

  const handleDeleteFile = (fileToDelete: string) => {
    const updatedFileUrls = fileUrls.filter((file) => file !== fileToDelete);
    setFileUrls(updatedFileUrls);
  };

  const handleSelectFile = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedFiles = event.target.value as string[];
    setFileUrls(selectedFiles);
  };

  useEffect(() => {
    form.change(source, fileUrls);
  }, [fileUrls, form, source]);

  return (
    <div>
      <FormControl fullWidth className="multiLineInput">
        <InputLabel style={{ padding: 10 }}>Select or Add File URLs</InputLabel>
        <Select
          multiple
          value={fileUrls}
          onChange={handleSelectFile}
          fullWidth
          multiline
          renderValue={(selected) => (
            <div
              style={{
                overflow: "hidden",
                overflowX: "auto",
              }}
            >
              {(Array.isArray(selected) ? selected : []).map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => handleDeleteFile(value)}
                  deleteIcon={
                    <CancelOutlined
                      style={{
                        color: "var(--error)",
                        zIndex: 9999,
                        padding: 3,
                      }}
                    />
                  }
                  style={{
                    margin: 5,
                    borderRadius: 4,
                    padding: 3,
                    backgroundColor: "var(--primary)",
                    color: "white",
                  }}
                />
              ))}
            </div>
          )}
        >
          {availableFileUrls.map((url) => (
            <MenuItem key={url} value={url}>
              {url}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FileManager;
