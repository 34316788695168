export enum EnumActivityActivityType {
  OutboundCall = "OutboundCall",
  OutboundMail = "OutboundMail",
  OutboundLetter = "OutboundLetter",
  OutboundWhatsapp = "OutboundWhatsapp",
  OutboundSms = "OutboundSms",
  Response = "Response",
  Other = "Other",
  InternalFeedback = "InternalFeedback",
  CreditorInformation = "CreditorInformation",
  CreditorFeedback = "CreditorFeedback",
  InternalDecision = "InternalDecision",
  Research = "Research",
  JudicialActivity = "JudicialActivity",
  InsolvencyActivity = "InsolvencyActivity",
  CreditorDecision = "CreditorDecision",
  RecommendedOutboundMail = "RecommendedOutboundMail",
  RecommendedInternalFeedback = "RecommendedInternalFeedback",
  AiCreditorLetterSummary = "AiCreditorLetterSummary",
  AiProposeLetterResponseToDebtorMail = "AiProposeLetterResponseToDebtorMail",
  AiProposePaymentPlan = "AiProposePaymentPlan",
}
