import { withStyles } from "@material-ui/core";
import { TabbedShowLayout } from "react-admin";

export const TabbedShowLayoutStyled = withStyles({
  content: {
    overflow: "hidden",
    overflowX: "scroll",
    marginBottom: 70,
  },
})(({ ...props }: any) => {
  return <TabbedShowLayout {...props} />;
});
