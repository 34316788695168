import React, { useEffect } from "react";
import { useNotify } from "react-admin";
import { jwtAuthProvider } from "./ra-auth-jwt";

const createAuthProvider = () => {
  let notifyFn: any = null;

  const setNotify = (notify: any) => {
    notifyFn = notify;
  };

  return {
    ...jwtAuthProvider,
    login: async (params: any) => {
      if (notifyFn) {
        params.notify = notifyFn;
      }
      return jwtAuthProvider.login(params);
    },
    checkAuth: async (params?: any) => {
      if (notifyFn) {
        params = { ...params, notify: notifyFn };
      }
      return jwtAuthProvider.checkAuth(params);
    },
    setNotify,
  };
};

export const authProvider = createAuthProvider();

interface AuthProviderHelperProps {
  children: React.ReactNode;
}

export const AuthProviderHelper = ({ children }: AuthProviderHelperProps) => {
  const notify = useNotify();

  useEffect(() => {
    authProvider.setNotify(notify);
  }, [notify]);

  return <React.Fragment>{children}</React.Fragment>;
};
