import { Card, Grid, Tooltip, Typography } from "@material-ui/core";
import { CloseRounded, StarBorderOutlined } from "@material-ui/icons";
import { keyBy } from "lodash";
import { useEffect, useState } from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  RichTextField,
  Title,
  useDataProvider,
  useGetList,
  useRefresh,
} from "react-admin";
import { Claim } from "../api/claim/Claim";
import { User } from "../api/user/User";
import { useManagers } from "../context/hook/useManagers";
import { theme } from "../theme/theme";

const DraggableCard = ({ label, value, subTitle, onDropText, onRemoval }) => {
  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text/plain");
    onDropText(data); // Trigger your custom function
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Required to allow dropping
  };

  return (
    <Card
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      variant="outlined"
      style={{
        height: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        margin: 8,
        marginBottom: 64,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography component={"span"} variant="h5">
        &nbsp;{label}&nbsp;
      </Typography>
      <Typography component={"span"} variant="h4">
        {value}
      </Typography>
      <span
        style={{
          fontWeight: 600,
          color: theme.palette.info.dark,
          fontSize: 12,
          borderRadius: 8,
          paddingLeft: 10,
          marginLeft: 0,
        }}
      >
        {subTitle &&
          subTitle.map((item, index) => (
            <div
              key={index}
              style={{
                color: item.isVerified
                  ? theme.palette.info.dark
                  : theme.palette.error.dark,
              }}
            >
              {item.name.trim()}{" "}
              <CloseRounded
                style={{
                  background: theme.palette.grey[100],
                  borderRadius: "100%",
                  cursor: "pointer",
                  height: 16,
                  width: 16,
                  position: "relative",
                  top: 3,
                }}
                onClick={() => onRemoval(item.id, label)}
              />
            </div>
          ))}
        {onDropText}
      </span>
    </Card>
  );
};

export const OperationsCategoriesOverview = () => {
  const [categoryTotals, setCategoryTotals] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [creditorTotals, setCreditorTotals] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");

  const pushCreditorTotal = (item: {
    id: string;
    operatorIds: string[];
    total: number;
  }) => {
    setCreditorTotals((prev) => {
      const existingItem = prev.find((i) => i.id === item.id);
      if (existingItem) {
        existingItem.total += item.total;
        existingItem.operatorIds.push(...item.operatorIds);
      } else {
        prev.push({
          id: item.id,
          operatorIds: item.operatorIds,
          total: item.total,
        });
      }
      return prev;
    });
  };

  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleDropText = async (text, groupName) => {
    const type = text.includes("operator:") ? "user" : "creditor";
    const username = text.replace("operator:", "").replace("creditor:", "");

    if (type === "user") {
      // Fetch the list of users filtered by groupName
      const { data: users } = await dataProvider.getList("User", {
        filter: {
          operatorCategory: {
            equals: groupName,
          },
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
      });

      // Loop over each user and update one by one
      for (const user of users) {
        await dataProvider.update("User", {
          id: user.id,
          previousData: { ...user }, // Explicitly include the user's existing data
          data: {
            ...user,
            operators: {
              connect: {
                id: username,
              },
            },
          },
        });
      }
    } else if (groupName) {
      // Fetch the list of users filtered by groupName
      const { data: otherCreditors } = await dataProvider.getList("User", {
        filter: {
          operatorCategory: {
            equals: groupName,
          },
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "ASC" },
      });

      // Use first existing creditor for linked values and apply them
      const { data: newUser } = await dataProvider.getList("User", {
        filter: {
          businessName: {
            equals: username,
          },
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
      });
      if (newUser && newUser.length) {
        await dataProvider.update("User", {
          id: newUser[0].id,
          previousData: { ...newUser[0] }, // Explicitly include the user's existing data
          data: {
            ...newUser[0],
            operatorCategory: groupName,
            ...(otherCreditors?.length &&
            otherCreditors?.[0].operators &&
            otherCreditors?.[0].operators.length
              ? {
                  operators: {
                    set: otherCreditors?.[0].operators.map((op) => ({
                      id: op.id,
                    })),
                  },
                }
              : {
                  operators: {
                    set: [],
                  },
                }),
          },
        });
      }
    }

    setCategoryTotals([]);
    refresh(true);
  };

  const handleRemoval = async (userId, groupName) => {
    // Fetch the list of users filtered by groupName
    const { data: users } = await dataProvider.getList("User", {
      filter: {
        operatorCategory: {
          equals: groupName,
        },
      },
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "id", order: "ASC" },
    });

    // Loop over each user and update one by one
    for (const user of users) {
      await dataProvider.update("User", {
        id: user.id,
        previousData: { ...user }, // Explicitly include the user's existing data
        data: {
          ...user,
          operators: {
            disconnect: {
              id: userId,
            },
          },
        },
      });
    }
    setCategoryTotals([]);
    refresh(true);
  };

  const { data: creditors, total: creditorsTotal } = useGetList<User>(
    "User",
    { page: 1, perPage: 1000 },
    { field: "operatorCategory", order: "ASC" },
    {
      claimsAsCreditor: {
        some: {
          stage: "Precourt",
        },
      },
      operators: {
        some: {},
      },
    }
  );

  useEffect(() => {
    setCreditorTotals([...creditorTotals]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTotals]);

  const { managers } = useManagers();

  useEffect(() => {
    if (
      managers &&
      creditorTotals &&
      creditorTotals.length &&
      Object.entries(managers).length
    ) {
      setLoadedUsers(
        Object.entries(managers).map(([_key, user]) => {
          const matchedCreditors = creditorTotals.filter((creditor) => {
            return creditor.operatorIds.includes(user.id);
          });

          const totalFromCreditors = matchedCreditors.reduce(
            (sum, matchedCreditor) => {
              if (matchedCreditor.total && matchedCreditor.operatorIds.length) {
                return parseFloat(
                  (
                    sum +
                    parseFloat(
                      (
                        matchedCreditor.total /
                        matchedCreditor.operatorIds.length
                      ).toFixed(2)
                    )
                  ).toFixed(2)
                );
              }
              return sum;
            },
            0
          );

          return {
            id: user.id,
            contactName: user.contactName || "-",
            comment: user.comment,
            total: parseFloat(
              (((user as any).total || 0) + totalFromCreditors).toFixed(0)
            ),
          };
        })
      );
    }
  }, [creditorTotals, managers]);

  // todo: filter non-operator creditor claims somehow (needs controller endpoint probably)
  const { total: allClaims } = useGetList<Claim>(
    "Claim",
    { page: 1, perPage: 1 },
    { field: "updatedAt", order: "ASC" },
    {
      status: "EncashmentLevel",
      activities: {
        some: {
          claimAction: "FirstDunning",
        },
        none: {
          claimAction: "SixthDunning",
        },
      },
    },
    {
      enabled: creditorsTotal > 0,
    }
  );

  return (
    <>
      <Title title="Operations Categories" />
      <Grid container>
        <Grid xs={10}>
          <Grid container>
            {allClaims && (
              <Grid item xs={3}>
                <DraggableCard
                  label={"All Claims"}
                  value={allClaims}
                  subTitle={""}
                  onDropText={() => {
                    return;
                  }}
                  onRemoval={() => {
                    return;
                  }}
                />
              </Grid>
            )}

            {categoryTotals
              .filter((item) => !!item.label || item.value === "+")
              .map(({ label, value, subTitle }) => (
                <Grid key={label} item xs={3}>
                  <DraggableCard
                    label={label}
                    value={value}
                    subTitle={subTitle}
                    onDropText={(textValue) => handleDropText(textValue, label)}
                    onRemoval={handleRemoval}
                  />
                </Grid>
              ))}
            <Grid item xs={3} style={{ position: "relative" }}>
              <input
                style={{
                  position: "absolute",
                  top: 35,
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 99,
                  borderRadius: 8,
                  width: "80%",
                  height: 35,
                  fontSize: 18,
                  border: 0,
                  textAlign: "center",
                }}
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                onDragOver={(e) => {
                  e.preventDefault(); // Prevent default drag-over behavior
                  e.stopPropagation(); // Stop event from propagating to parent elements
                }}
                onDrop={(e) => {
                  console.log("e", e);
                  e.preventDefault(); // Prevent default drop behavior
                  e.stopPropagation(); // Stop event from propagating to parent elements
                  const textValue = e.dataTransfer.getData("text/plain");
                  handleDropText(textValue, newGroupName);
                  setNewGroupName("");
                }}
              />
              <DraggableCard
                label={newGroupName}
                value={"+"}
                subTitle={""}
                onDropText={(textValue) => {
                  handleDropText(textValue, newGroupName);
                  setNewGroupName("");
                }}
                onRemoval={handleRemoval}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {loadedUsers.map(({ contactName, id, comment, total }) => (
            <Tooltip key={id} title={`${comment || ""}`}>
              <div
                draggable
                onDragStart={(e) =>
                  e.dataTransfer.setData("text/plain", "operator:" + id)
                }
                style={{
                  padding: "8px",
                  margin: "8px",
                  borderRadius: 8,
                  backgroundColor: "#ccc",
                  cursor: "grab",
                }}
              >
                {contactName}
                {!!total ? <b> ({total})</b> : ""}
              </div>
            </Tooltip>
          ))}
        </Grid>
      </Grid>

      <ListContextProvider
        value={{
          data: keyBy(creditors, "id"),
          ids: Object.keys(creditors).map((key) => key),
          currentSort: { field: "id", order: "ASC" },
          basePath: "/user",
          resource: "User",
          selectedIds: [],
          total: creditorsTotal,
          page: 1,
          perPage: 100,
        }}
      >
        <Datagrid rowClick="show" optimized>
          <FunctionField
            label="Name"
            emptyText=""
            render={(record: any) => (
              <div
                draggable
                onDragStart={(event) => {
                  // Set the data to be transferred during drag
                  event.dataTransfer.setData(
                    "text/plain",
                    "creditor:" + record.businessName || record.contactName
                  );
                }}
                style={{
                  cursor: "grab", // Change cursor to indicate draggable
                  padding: "5px",
                  background: "#f0f0f0",
                  borderRadius: "4px",
                }}
              >
                {record.businessName || record.contactName}
              </div>
            )}
          />

          <FunctionField
            label="Active Claims"
            emptyText=""
            render={(record: any) => (
              <ClaimsAsCreditorCount
                creditorData={record}
                setCategoryTotals={setCategoryTotals}
                pushCreditorTotal={pushCreditorTotal}
              />
            )}
          />

          <FunctionField
            label="VIP"
            emptyText=""
            render={(record: any) => (
              <span>
                {record?.isVip ? (
                  <Tooltip title={"User is VIP"}>
                    <div
                      style={{
                        color: "rgb(246, 170, 80)",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StarBorderOutlined />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={"User is not VIP"}>
                    <div
                      style={{
                        color: "grey",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StarBorderOutlined />
                    </div>
                  </Tooltip>
                )}
              </span>
            )}
          />

          <FunctionField
            label="Operators"
            emptyText=""
            render={(record: any) => (
              <span>
                <Operators creditorData={record} />
              </span>
            )}
          />

          <RichTextField
            label="Category"
            source="operatorCategory"
            emptyText=" "
            style={{
              wordBreak: "break-word",
              minWidth: 350,
              display: "inline-block",
              whiteSpace: "pre-wrap",
            }}
          />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};

const Operators = ({ creditorData }) => {
  const dataProvider = useDataProvider();
  const [operators, setOperators] = useState<any[]>([]);

  useEffect(() => {
    const fetchOperators = async () => {
      if (creditorData?.operators?.length) {
        try {
          const operatorIds = creditorData.operators.map((op) => op.id);
          const { data } = await dataProvider.getMany("User", {
            ids: operatorIds,
          });
          setOperators(data);
        } catch (error) {
          console.error("Error fetching operator names:", error);
        }
      }
    };

    fetchOperators();
  }, [creditorData.operators, dataProvider]);

  return (
    <span>
      {operators.map((operator) => (
        <Tooltip
          key={operator.id}
          title={!operator.isVerified ? "Operator is away" : undefined}
          placement="top"
        >
          <span
            style={{
              fontWeight: 600,
              color: !operator.isVerified
                ? theme.palette.error.dark
                : theme.palette.info.dark,
              fontSize: 12,
              borderRadius: 8,
              paddingLeft: 2,
              marginLeft: 0,
              display: "inline-block",
              marginRight: 4,
            }}
          >
            {operator.contactName}
          </span>
        </Tooltip>
      ))}
    </span>
  );
};

const ClaimsAsCreditorCount = ({
  creditorData,
  setCategoryTotals,
  pushCreditorTotal,
}) => {
  const dataProvider = useDataProvider();

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClaimsCount = async () => {
      try {
        setLoading(true);
        const { total } = await dataProvider.getList("Claim", {
          filter: {
            creditor: { id: creditorData?.id },
            status: "EncashmentLevel",
            activities: {
              some: {
                claimAction: "FirstDunning",
              },
              none: {
                claimAction: "SixthDunning",
              },
            },
          },
          pagination: { page: 1, perPage: 1 }, // Only fetch the count
          sort: { field: "id", order: "ASC" },
        });
        setTotal(total || 0);
        const operatorIds = creditorData.operators.map((op) => op.id);
        pushCreditorTotal({
          id: creditorData.id,
          operatorIds: operatorIds,
          total: total,
        });
        const { data: operators } = await dataProvider.getList("User", {
          filter: {
            id: { in: operatorIds },
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
        });

        setCategoryTotals((prev) =>
          prev.find((item) => item.label === creditorData.operatorCategory)
            ? prev.map((item) =>
                item.label === creditorData.operatorCategory
                  ? { ...item, value: item.value + total }
                  : item
              )
            : [
                ...prev,
                {
                  label: creditorData.operatorCategory,
                  value: total,
                  subTitle: operators.map((op) => ({
                    name: op.contactName || op.name || op.username,
                    id: op.id,
                    isVerified: op.isVerified,
                  })),
                },
              ]
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchClaimsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditorData.id, dataProvider]);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;

  return (
    <span
      style={{
        fontWeight: 600,
        color: theme.palette.info.dark,
        fontSize: 12,
        borderRadius: 8,
        paddingLeft: 10,
        marginLeft: 0,
      }}
    >
      {total}
    </span>
  );
};
