import { EventEmitter } from "events";
import { CREDENTIALS_LOCAL_STORAGE_ITEM } from "./constants";
import { Credentials } from "./types";

const eventEmitter = new EventEmitter();
const TOKEN_EXPIRY_DAYS = 7;
const WARNING_MINUTES = 3;
let expirationTimer: NodeJS.Timeout | null = null;
let warningTimer: NodeJS.Timeout | null = null;

export function isAuthenticated(): boolean {
  return Boolean(getCredentials());
}

export function listen(listener: (authenticated: boolean) => void): void {
  eventEmitter.on("change", () => {
    listener(isAuthenticated());
  });
}

export function listenToExpiration(
  listener: (minutesLeft: number) => void
): void {
  eventEmitter.on("tokenExpiring", listener);
}

export function removeExpirationListener(
  listener: (minutesLeft: number) => void
): void {
  eventEmitter.off("tokenExpiring", listener);
}

export function setCredentials(credentials: Credentials) {
  const credentialsToStore = {
    ...credentials,
    tokenTimestamp: Date.now(),
  };

  localStorage.setItem(
    CREDENTIALS_LOCAL_STORAGE_ITEM,
    JSON.stringify(credentialsToStore)
  );

  setupExpirationTimers();
}

export function getCredentials(): Credentials | null {
  const raw = localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_ITEM);
  if (raw === null) {
    return null;
  }
  try {
    return JSON.parse(raw);
  } catch {
    // If it's not JSON, it might be the bearer token
    return {
      bearerToken: raw,
      tokenTimestamp: Date.now() - 1000, // Subtract a second to ensure proper timing
    } as Credentials;
  }
}

export function removeCredentials(): void {
  localStorage.removeItem(CREDENTIALS_LOCAL_STORAGE_ITEM);
  clearExpirationTimers();
}

function setupExpirationTimers() {
  clearExpirationTimers();

  const credentials = getCredentials();
  if (!credentials?.tokenTimestamp) return;

  const expiryTime =
    credentials.tokenTimestamp + TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
  const warningTime = expiryTime - WARNING_MINUTES * 60 * 1000;
  const now = Date.now();

  if (now >= expiryTime) {
    removeCredentials();
    eventEmitter.emit("change");
    return;
  }

  if (now < warningTime) {
    warningTimer = setTimeout(() => {
      eventEmitter.emit("tokenExpiring", WARNING_MINUTES);
    }, warningTime - now);
  }

  expirationTimer = setTimeout(() => {
    removeCredentials();
    eventEmitter.emit("change");
  }, expiryTime - now);
}

function clearExpirationTimers() {
  if (warningTimer) {
    clearTimeout(warningTimer);
    warningTimer = null;
  }
  if (expirationTimer) {
    clearTimeout(expirationTimer);
    expirationTimer = null;
  }
}

// Check expiration on page load
setupExpirationTimers();
