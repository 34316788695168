import { Badge, Button, makeStyles, Tab, Tabs } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { LoadingIndicator, useGetIdentity } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { VIP_USERS } from "../../../config/AppConfig";
import { useAircall } from "../../../context/hook/useAircall";
import { useClaim } from "../../../context/hook/useClaim";
import { theme } from "../../../theme/theme";
import { ClaimDrawer } from "../../ClaimDrawer";
import { ActivitiesTab } from "./tabs/ActivitiesTab";

const GeneralTab = React.lazy(() => import("./tabs/GeneralTab"));
const PaymentsTab = React.lazy(() => import("./tabs/PaymentsTab"));
const CommentTab = React.lazy(() => import("./tabs/CommentTab"));
const FilesTab = React.lazy(() => import("./tabs/FilesTab"));

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    animation: "$flash 0.5s ease-in-out 1",
  },
  "@keyframes flash": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },
  MuiTabsIndicator: {
    backgroundColor: theme.palette.common.white,
  },
  "@keyframes iconBounce": {
    "0%, 100%": {
      transform: "scale(1) rotate(0deg)",
    },
    "25%": {
      transform: "scale(1.1) rotate(-10deg)",
    },
    "50%": {
      transform: "scale(1.2) rotate(0deg)",
    },
    "75%": {
      transform: "scale(1.1) rotate(10deg)",
    },
  },
  icon: {
    animation: "$iconBounce 0.5s ease-in-out",
  },
}));

export const ClaimTabs = React.memo((props: any) => {
  const {
    InfoOutlined,
    PaymentOutlined,
    TimelineOutlined,
    FolderOutlined,
    ForumOutlined,
    CallOutlined,
  } = icons;

  const [showActivityAdd, setShowActivityAdd] = useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] = useState("");
  const { identity } = useGetIdentity();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);

  const { record, absoluteTotal, debtorData, creditorData } = useClaim(
    props.claimId
  );
  const { hidePhone, handleCallDebtor } = useAircall(record.id);
  const classes = useStyles();

  // Initialize tab from URL parameters
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    const tabFromUrl = parseInt(lastSegment, 10);

    if (!isNaN(tabFromUrl) && tabFromUrl >= 0) {
      setTabValue(tabFromUrl);
    } else {
      setTabValue(0); // Default to the first tab if invalid
    }
  }, [location.pathname]);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
      const pathSegments = location.pathname.split("/");
      const lastSegment = pathSegments[pathSegments.length - 2];

      const newPath =
        lastSegment === "show"
          ? pathSegments.slice(0, -1).join("/") + `/${newValue}`
          : pathSegments.join("/") + `/${newValue}`;

      history.push(newPath);
    },
    [history, location.pathname]
  );

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        style={{
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Tab
          label="General"
          icon={<InfoOutlined className={tabValue === 0 ? classes.icon : ""} />}
          classes={{
            selected: classes.selected,
          }}
        />
        <Tab
          label="Payments"
          icon={
            <PaymentOutlined className={tabValue === 1 ? classes.icon : ""} />
          }
          classes={{
            selected: classes.selected,
          }}
        />
        <Tab
          label="Activities"
          icon={
            <TimelineOutlined className={tabValue === 2 ? classes.icon : ""} />
          }
          classes={{
            selected: classes.selected,
          }}
        />
        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab
            label="Files"
            icon={
              <FolderOutlined className={tabValue === 3 ? classes.icon : ""} />
            }
            classes={{
              selected: classes.selected,
            }}
          />
        )}
        <Tab
          label="Comment"
          classes={{
            selected: classes.selected,
          }}
          icon={
            <Badge
              invisible={!record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              overlap="rectangular"
              style={{ marginTop: 4 }}
            >
              <ForumOutlined className={tabValue === 4 ? classes.icon : ""} />
            </Badge>
          }
        />
        {!hidePhone && (
          <Button
            hidden={hidePhone}
            title="Call Debtor"
            onClick={handleCallDebtor}
            style={{
              position: "absolute",
              right: 25,
              top: "50%",
              padding: 8,
              transform: "translateY(-50%)",
            }}
            size="small"
          >
            <>
              <CallOutlined />
              {(!debtorData.phoneVerificationStatus ||
                debtorData.phoneVerificationStatus === "UNKNOWN") && (
                <icons.Help
                  style={{
                    height: 14,
                    color: theme.palette.warning.dark,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "VALID" && (
                <icons.VerifiedUser
                  style={{
                    height: 14,
                    color: theme.palette.success.main,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "INVALID" && (
                <icons.RemoveCircle
                  style={{
                    height: 14,
                    color: theme.palette.error.main,
                  }}
                />
              )}
            </>
          </Button>
        )}
      </Tabs>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
            backgroundColor: "#f9fbff",
          }}
          className="notranslate"
          hidden={tabValue !== 0}
          id={`vertical-tabpanel-${0}`}
        >
          <GeneralTab claimId={props?.claimId} />{" "}
          {
            // always render it, contains important stuff we need on all tabs
          }
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 1}
          id={`vertical-tabpanel-${1}`}
        >
          {tabValue === 1 && <PaymentsTab absoluteTotal={absoluteTotal} />}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 2}
          id={`vertical-tabpanel-${2}`}
        >
          {tabValue === 2 && (
            <ActivitiesTab
              showActivityAdd={showActivityAdd}
              setShowActivityAdd={setShowActivityAdd}
              activityPlaceholderText={activityPlaceholderText}
              setActivityPlaceholderText={setActivityPlaceholderText}
              creditor={creditorData}
              debtor={debtorData}
            />
          )}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 3}
          id={`vertical-tabpanel-${3}`}
        >
          {tabValue === 3 && <FilesTab />}
        </div>
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        <div
          role="tabpanel"
          style={{
            padding: "1rem",
          }}
          className="notranslate"
          hidden={tabValue !== 4}
          id={`vertical-tabpanel-${4}`}
        >
          {tabValue === 4 && <CommentTab />}
        </div>
      </Suspense>
      {VIP_USERS.includes(record?.identity?.username) && <ClaimDrawer />}
    </div>
  );
});
