import { Title } from "react-admin";
import { SalesToolsCard } from "../Components/cards/SalesToolsCard";

export const SalesTools = () => {
  return (
    <>
      <Title title="Sales Tools" />
      <SalesToolsCard />
    </>
  );
};
