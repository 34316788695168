import { useCallback } from "react";
import { useFormState } from "react-final-form";

interface ValidationRules {
  [key: string]: {
    required?: boolean;
    pattern?: RegExp;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
    customValidator?: (value: any) => boolean;
  };
}

function useFormValidation(rules?: ValidationRules) {
  const { values } = useFormState();

  const validateFields = useCallback(() => {
    const errors: Record<string, string> = {};

    Object.entries(rules).forEach(([field, fieldRules]) => {
      const value = values[field];

      if (
        fieldRules.required &&
        (!value || (typeof value === "string" && value.trim() === ""))
      ) {
        errors[field] = `${field} is required`;
        return;
      }

      if (value) {
        if (fieldRules.pattern && !fieldRules.pattern.test(value)) {
          errors[field] = `${field} format is invalid`;
        }

        if (typeof value === "string") {
          if (fieldRules.minLength && value.length < fieldRules.minLength) {
            errors[
              field
            ] = `${field} must be at least ${fieldRules.minLength} characters`;
          }
          if (fieldRules.maxLength && value.length > fieldRules.maxLength) {
            errors[
              field
            ] = `${field} must not exceed ${fieldRules.maxLength} characters`;
          }
        }

        if (fieldRules.customValidator && !fieldRules.customValidator(value)) {
          errors[field] = `${field} is invalid`;
        }
      }
    });

    return errors;
  }, [rules, values]);

  return {
    validateFields,
    hasErrors: () => Object.keys(validateFields()).length > 0,
    getErrors: validateFields,
  };
}

export default useFormValidation;
