import React from "react";
import { ClaimShowSidebar } from "../../Components/sidebar/ClaimShowSidebar";

export const useClaimShowLogic = (claimId: string | undefined) => {
  const [absoluteTotal, setAbsoluteTotal] = React.useState(0);

  const aside = React.useMemo(
    () => (
      <ClaimShowSidebar claimId={claimId} setAbsoluteTotal={setAbsoluteTotal} />
    ),
    [claimId]
  );

  return { aside, absoluteTotal };
};
