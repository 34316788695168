export enum EnumAddressCountry {
  DEU = "DEU",
  AUT = "AUT",
  CHE = "CHE",
  CHN = "CHN",
  ITA = "ITA",
  NLD = "NLD",
  NZL = "NZL",
  POL = "POL",
  EST = "EST",
  FRA = "FRA",
  ESP = "ESP",
  BEL = "BEL",
  GBR = "GBR",
  LUX = "LUX",
  ISL = "ISL",
  IRL = "IRL",
  TUR = "TUR",
  IND = "IND",
  MEX = "MEX",
  ISR = "ISR",
  BRA = "BRA",
  GRC = "GRC",
  TUN = "TUN",
  USA = "USA",
  DZA = "DZA",
  PRT = "PRT",
  MAR = "MAR",
  BGR = "BGR",
  BGD = "BGD",
  BLR = "BLR",
  OMN = "OMN",
  TWN = "TWN",
  DNK = "DNK",
  DOM = "DOM",
  CAN = "CAN",
  AUS = "AUS",
  ZAF = "ZAF",
  SRB = "SRB",
  ROU = "ROU",
  NGA = "NGA",
  EGY = "EGY",
  UGA = "UGA",
  SAU = "SAU",
  HUN = "HUN",
  HKG = "HKG",
  CYM = "CYM",
  SGP = "SGP",
  CHL = "CHL",
  KOR = "KOR",
  RUS = "RUS",
  CZE = "CZE",
  UKR = "UKR",
  LVA = "LVA",
  ARE = "ARE",
  SWE = "SWE",
  ALB = "ALB",
  BIH = "BIH",
  XKX = "XKX",
  HRV = "HRV",
  MNE = "MNE",
  MKD = "MKD",
  SVN = "SVN",
  SVK = "SVK",
  IDN = "IDN",
  NOR = "NOR",
  FIN = "FIN",
  MLT = "MLT",
  PHL = "PHL",
  MYS = "MYS",
  VNM = "VNM",
  GHA = "GHA",
  THA = "THA",
  PAK = "PAK",
  ZWE = "ZWE",
  KEN = "KEN",
  ARG = "ARG",
  PER = "PER",
  COL = "COL",
  KWT = "KWT",
  HND = "HND",
  LTU = "LTU",
  CYP = "CYP",
  QAT = "QAT",
  JPN = "JPN",
  JOR = "JOR",
  SEN = "SEN",
  LBN = "LBN",
  IRQ = "IRQ",
  SYR = "SYR",
  AFG = "AFG",
  YEM = "YEM",
  IRN = "IRN",
  ARM = "ARM",
  AZE = "AZE",
  GEO = "GEO",
  KGZ = "KGZ",
  KAZ = "KAZ",
  TKM = "TKM",
  UZB = "UZB",
  TJK = "TJK",
  ERI = "ERI",
  RUA = "RUA",
  BEN = "BEN",
  CMR = "CMR",
  GIN = "GIN",
  TGO = "TGO",
  CIV = "CIV",
  BFA = "BFA",
  BDI = "BDI",
  CPV = "CPV",
  CAF = "CAF",
  COM = "COM",
  COD = "COD",
  DJI = "DJI",
  GNQ = "GNQ",
  SWZ = "SWZ",
  ETH = "ETH",
  GAB = "GAB",
  GMB = "GMB",
  LSO = "LSO",
  LBR = "LBR",
  MDG = "MDG",
  MWI = "MWI",
  MLI = "MLI",
  MOZ = "MOZ",
  NAM = "NAM",
  NER = "NER",
  RWA = "RWA",
  STP = "STP",
  SYC = "SYC",
  SLE = "SLE",
  SOM = "SOM",
  SSD = "SSD",
  SDN = "SDN",
  TZA = "TZA",
  ZMB = "ZMB",
  JAM = "JAM",
  VEN = "VEN",
  URY = "URY",
  AGO = "AGO",
  GTM = "GTM",
  SLV = "SLV",
  BOL = "BOL",
  CRI = "CRI",
  CUB = "CUB",
  ECU = "ECU",
  HTI = "HTI",
  NIC = "NIC",
  PAN = "PAN",
  PRY = "PRY",
  GLP = "GLP",
  BHR = "BHR",
  MNG = "MNG",
  BMU = "BMU",
  JEY = "JEY",
  VCT = "VCT",
  KHM = "KHM",
  LIE = "LIE",
  LKA = "LKA",
  MDA = "MDA",
}

export const GERMAN_SPEAKING_COUNTRIES = [
  EnumAddressCountry.DEU,
  EnumAddressCountry.AUT,
  EnumAddressCountry.CHE,
];

export const FRENCH_SPEAKING_COUNTRIES = [EnumAddressCountry.FRA];

// Map each state/territory to its corresponding IANA timezone name
const AUSTRALIAN_TIMEZONES_BY_STATE: { [state: string]: string } = {
  ACT: "Australia/Sydney",
  NSW: "Australia/Sydney",
  VIC: "Australia/Melbourne",
  QLD: "Australia/Brisbane",
  SA: "Australia/Adelaide",
  WA: "Australia/Perth",
  TAS: "Australia/Hobart",
  NT: "Australia/Darwin",
};

const AUSTRALIAN_POSTCODE_RANGES: any[] = [
  // Australian Capital Territory
  { from: 200, to: 299, state: "ACT" },
  { from: 2600, to: 2618, state: "ACT" },
  { from: 2620, to: 2626, state: "ACT" },
  { from: 2900, to: 2920, state: "ACT" },
  { from: 2922, to: 2929, state: "ACT" },

  // New South Wales
  { from: 1000, to: 1999, state: "NSW" },
  { from: 2000, to: 2599, state: "NSW" },
  { from: 2619, to: 2898, state: "NSW" },
  { from: 2990, to: 2999, state: "NSW" },

  // Victoria
  { from: 3000, to: 3999, state: "VIC" },
  { from: 8000, to: 8999, state: "VIC" },

  // Queensland
  { from: 4000, to: 4999, state: "QLD" },
  { from: 9000, to: 9999, state: "QLD" },

  // South Australia
  { from: 5000, to: 5799, state: "SA" },
  { from: 5800, to: 5999, state: "SA" },

  // Western Australia
  { from: 6000, to: 6797, state: "WA" },
  { from: 6800, to: 6999, state: "WA" },

  // Tasmania
  { from: 7000, to: 7799, state: "TAS" },
  { from: 7800, to: 7999, state: "TAS" },

  // Northern Territory
  { from: 800, to: 899, state: "NT" },
  { from: 900, to: 999, state: "NT" },
];

// so far, only AUS is supported
export function getTimezoneNameByCountryAndZip(
  iso2CountryCode: string,
  zipCode: string
): string | null {
  // Ensure the country code is uppercase for consistency
  if (iso2CountryCode.toUpperCase() !== "AU") {
    return null;
  }

  // Remove any non-digit characters from the postcode
  const cleanedZip = zipCode.replace(/\D/g, "");

  // Australian postcodes must be exactly 4 digits
  if (cleanedZip.length !== 4) {
    return null;
  }

  // Parse the postcode as a number
  const zipNumber = parseInt(cleanedZip, 10);

  if (isNaN(zipNumber)) {
    return null;
  }

  // Iterate through the postcode ranges to find the corresponding state
  for (const range of AUSTRALIAN_POSTCODE_RANGES) {
    if (zipNumber >= range.from && zipNumber <= range.to) {
      const state = range.state;
      const timezone = AUSTRALIAN_TIMEZONES_BY_STATE[state];
      if (timezone) {
        return timezone;
      } else {
        // State found but no timezone mapping available
        return null;
      }
    }
  }

  // No matching postcode range found
  return null;
}
