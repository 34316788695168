export enum EnumPaymentPaymentType {
  DunningCostExpense = "DunningCostExpense",
  DunningCostFee = "DunningCostFee",
  DefaultInterest = "DefaultInterest",
  ExistingCreditorExtras = "ExistingCreditorExtras",
  WriteOffDiscount = "WriteOffDiscount",
  DebtClearance = "DebtClearance",
  PaymentRate = "PaymentRate",
  Payout = "Payout",
  ExistingPayments = "ExistingPayments",
  CreditorExpenses = "CreditorExpenses",
  ClaimPosition = "ClaimPosition",
  CreditorInterest = "CreditorInterest",
  DebtCollectorTax = "DebtCollectorTax",
  DebtCollectorFee = "DebtCollectorFee",
  DebtCollectorOutboundPosition = "DebtCollectorOutboundPosition",
  DebtCollectorInboundPosition = "DebtCollectorInboundPosition",
}

export function getReadablePaymentType(
  paymentType: EnumPaymentPaymentType
): string {
  switch (paymentType) {
    case EnumPaymentPaymentType.DunningCostExpense:
      return "RVG Auslagen";
    case EnumPaymentPaymentType.DunningCostFee:
      return "RVG Gebühr";
    case EnumPaymentPaymentType.DefaultInterest:
      return "Verzugszinsen";
    case EnumPaymentPaymentType.ExistingCreditorExtras:
      return "Mahnkosten";
    case EnumPaymentPaymentType.WriteOffDiscount:
      return "Abschreibung / Rabatt";
    case EnumPaymentPaymentType.DebtClearance:
      return "Vollzahlung";
    case EnumPaymentPaymentType.PaymentRate:
      return "Teilzahlung";
    case EnumPaymentPaymentType.Payout:
      return "Abrechnung";
    case EnumPaymentPaymentType.ExistingPayments:
      return "Gläubigerzahlungen";
    case EnumPaymentPaymentType.CreditorExpenses:
      return "Gläubigerkosten";
    case EnumPaymentPaymentType.ClaimPosition:
      return "Position";
    case EnumPaymentPaymentType.CreditorInterest:
      return "Gläubigerzinsen";
    case EnumPaymentPaymentType.DebtCollectorTax:
      return "Inkasso Steuer";
    case EnumPaymentPaymentType.DebtCollectorFee:
      return "Inkasso Provision";
    case EnumPaymentPaymentType.DebtCollectorOutboundPosition:
      return "Eingehende Rechnung";
    case EnumPaymentPaymentType.DebtCollectorInboundPosition:
      return "Ausgehende Rechnung";
    default:
      return "Unbekannter Zahlungstyp";
  }
}

export const NEGATIVE_PAYMENT_TYPES = [
  EnumPaymentPaymentType.WriteOffDiscount,
  EnumPaymentPaymentType.DebtClearance,
  EnumPaymentPaymentType.PaymentRate,
  EnumPaymentPaymentType.ExistingPayments,
  EnumPaymentPaymentType.DebtCollectorInboundPosition,
];

export const THIRD_PARTY_PAYMENT_TYPES = [
  EnumPaymentPaymentType.DebtCollectorOutboundPosition,
  EnumPaymentPaymentType.DebtCollectorInboundPosition,
];
