import { Tooltip, Typography } from "@material-ui/core";
import {
  Assignment,
  FileCopyOutlined,
  Gavel,
  InfoRounded,
  Mail,
  MoneyOff,
  Notifications,
  PersonAdd,
  StarRounded,
  Visibility,
} from "@material-ui/icons";
import React, { useCallback, useMemo } from "react";
import {
  FunctionField,
  LoadingIndicator,
  Record,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { getFormattedClaimStatusChoices } from "../claim/ClaimCreate";
import { useClaim } from "../context/hook/useClaim";
import { useClaimPaymentData } from "../context/hook/useClaimPaymentData";
import { useLawyerStatus } from "../context/hook/useLawyerStatus";
import { theme } from "../theme/theme";
import { searchMapByKey } from "../util/MapUtils";
import { getInitials, stringToHslColor } from "../util/StyleUtils";

// Styles as constants to prevent recreation
const headerBarStyles = {
  position: "relative" as const,
};

const stageButtonBaseStyle = {
  maxWidth: 220,
  fontWeight: 600,
  whiteSpace: "nowrap" as const,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative" as const,
  color: "white",
  padding: "7px 10px",
  fontSize: 12,
  borderRadius: 4,
};

interface Address {
  id: string;
  country?: string;
  languageCountry?: string;
}

interface Integration {
  id: string;
  software?: string;
}

interface Operator {
  id: string;
  contactName?: string;
  name?: string;
  username?: string;
}

interface ExtendedRecord extends Record {
  businessType?: string;
  businessName?: string;
  contactName?: string;
  email?: string;
  comment?: string;
  isVip?: boolean;
  operatorCategory?: string;
  stage?: string;
  status?: string;
  reference?: string;
  address?: Address;
  integration?: Integration;
  operators?: Array<{ id: string }>;
  claimsAsDebtorIds?: string[];
}

interface ClaimHeaderBarProps {
  claimId: string;
}

interface Claim extends Record {
  id: string;
  reference?: string;
  stage?: string;
  status?: string;
  contactsIds?: string[];
}

interface DebtorSummaryProps {
  claim: Claim;
  setUserCountry: (country: string) => void;
  className?: string;
}

// Helper functions
const getColorFromString = (
  str: string | undefined,
  lightness: number
): string => {
  if (!str) return lightness === 30 ? "#000000" : "#FFFFFF";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return stringToHslColor(hash as number, lightness);
};

// Custom hooks
const useDebtorAddress = (
  addressId: string | undefined,
  onCountryChange: (country: string) => void
) => {
  const dataProvider = useDataProvider();
  const [country, setCountry] = React.useState<string>();

  React.useEffect(() => {
    if (!addressId) return;

    let isMounted = true;
    dataProvider
      .getOne<Address>("Address", { id: addressId })
      .then(({ data }) => {
        if (isMounted && data?.country) {
          setCountry(data.country);
          onCountryChange(data.country);
        }
      })
      .catch((error) => console.error("Error fetching address:", error));

    return () => {
      isMounted = false;
    };
  }, [addressId, dataProvider, onCountryChange]);

  return country;
};

const useCreditorData = (
  addressId: string | undefined,
  integrationId?: string
) => {
  const dataProvider = useDataProvider();
  const [data, setData] = React.useState<{
    language?: string;
    integrationFlag?: string;
  }>({});

  React.useEffect(() => {
    let isMounted = true;

    const promises = [];
    if (addressId) {
      promises.push(
        dataProvider
          .getOne<Address>("Address", { id: addressId })
          .then(({ data }) => data?.languageCountry)
          .catch((error) => {
            console.error("Error fetching address:", error);
            return undefined;
          })
      );
    }
    if (integrationId) {
      promises.push(
        dataProvider
          .getOne<Integration>("Integration", { id: integrationId })
          .then(({ data }) => data?.software)
          .catch((error) => {
            console.error("Error fetching integration:", error);
            return undefined;
          })
      );
    }

    Promise.all(promises).then(([language, integrationFlag]) => {
      if (isMounted) {
        setData({ language, integrationFlag });
      }
    });

    return () => {
      isMounted = false;
    };
  }, [addressId, integrationId, dataProvider]);

  return data;
};

const useOperatorNames = (operators: Array<{ id: string }> | undefined) => {
  const dataProvider = useDataProvider();
  const [operatorNames, setOperatorNames] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!operators?.length) return;

    let isMounted = true;
    const operatorIds = operators.map((op) => op.id);

    dataProvider
      .getMany<Operator>("User", { ids: operatorIds })
      .then(({ data }) => {
        if (isMounted) {
          setOperatorNames(
            data.map(
              (operator) =>
                operator.contactName || operator.name || operator.username || ""
            )
          );
        }
      })
      .catch((error) => console.error("Error fetching operator names:", error));

    return () => {
      isMounted = false;
    };
  }, [operators, dataProvider]);

  return operatorNames;
};

export const ClaimHeaderBar = React.memo(
  ({ claimId }: ClaimHeaderBarProps) => {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { record, setUserCountry, creditorData } = useClaim(claimId);
    const { statusToShow } = useClaimPaymentData(claimId);

    const handleStatusChange = useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.value || !record) return;

        if (
          ["Cancelled", "Paid", "Closed"].includes(e.target.value) &&
          !window.confirm("Are you sure?")
        ) {
          return;
        }

        try {
          const newRecord = { ...record };
          newRecord.status = e.target.value;
          await dataProvider.update("Claim", {
            id: record.id,
            data: newRecord,
            previousData: record,
          });
          refresh();
        } catch (error) {
          console.error("Error updating status:", error);
          notify("Error updating status", { type: "error" });
        }
      },
      [dataProvider, notify, record, refresh]
    );

    const getStageBackgroundColor = useCallback(() => {
      if (!record) return theme.palette.info.dark;

      if (["Paid", "PaidOut"].includes(statusToShow))
        return theme.palette.success.dark;
      if (statusToShow === "Paused") return theme.palette.grey[800];
      if (statusToShow === "Open") return theme.palette.primary.main;
      if (["Closed", "Cancelled"].includes(statusToShow))
        return theme.palette.error.main;
      if (record?.stage === "Court") return theme.palette.error.dark;
      if (record?.stage === "Precourt") return theme.palette.secondary.main;
      if (record?.stage === "Reminder") return theme.palette.info.main;
      return theme.palette.info.dark;
    }, [record, statusToShow]);

    if (!record || !creditorData) {
      return (
        <div className="claimHeaderBar">
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <div
        className="claimHeaderBar"
        style={{
          ...headerBarStyles,
          background: creditorData?.isVip
            ? "rgba(246, 170, 80, 0.10)"
            : undefined,
        }}
      >
        <div className="bar-item">
          <TextField
            style={{ fontWeight: 600 }}
            label="Reference (Internal Identifier)"
            source="reference"
          />
          <FileCopyOutlined
            style={{
              height: 12,
              cursor: "copy",
              position: "relative",
              top: 1,
            }}
            onClick={() => {
              navigator.clipboard.writeText(record?.reference || "");
              notify("Copied to clipboard", "info", null, false);
            }}
          />
        </div>
        <ReferenceField
          link="show"
          label="Creditor"
          source="creditor.id"
          reference="User"
          className="bar-item"
        >
          <CreditorSummary />
        </ReferenceField>
        <ReferenceField
          link="show"
          label="Debtor"
          source="debtor.id"
          reference="User"
          className="bar-item"
        >
          <DebtorSummary
            claim={record}
            className="bar-item"
            setUserCountry={setUserCountry}
          />
        </ReferenceField>

        <FunctionField
          label="Stage"
          className="bar-item"
          render={(record: ExtendedRecord) => (
            <span
              style={{
                ...stageButtonBaseStyle,
                background: getStageBackgroundColor(),
              }}
            >
              {record?.stage === "Precourt" ? (
                <Mail style={{ height: 14 }} />
              ) : record?.stage === "Court" ? (
                <Gavel style={{ height: 14 }} />
              ) : record?.stage === "Monitoring" ? (
                <Visibility style={{ height: 14 }} />
              ) : record?.stage === "Reminder" ? (
                <Notifications style={{ height: 14 }} />
              ) : null}
              {`${record?.stage && record?.stage} - ${statusToShow}`}
              <SimpleForm
                toolbar={false}
                style={{ position: "absolute", left: -20 }}
              >
                <SelectInput
                  source="status"
                  label="Status"
                  allowEmpty
                  emptyText="-- back --"
                  style={{ maxWidth: 180, opacity: 0 }}
                  onChange={handleStatusChange}
                  choices={getFormattedClaimStatusChoices()}
                  optionText="label"
                  optionValue="value"
                />
              </SimpleForm>
            </span>
          )}
        />
        <ReferenceField
          link="show"
          label="Assignee"
          source="assignee.id"
          reference="User"
        >
          <FunctionField
            link="show"
            label="Assignee"
            source="assignee.id"
            reference="User"
            render={(record: ExtendedRecord) => (
              <Tooltip title={record?.contactName}>
                <div className="assigned-user" style={{ margin: 0 }}>
                  {getInitials(record?.contactName)}
                </div>
              </Tooltip>
            )}
          />
        </ReferenceField>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.claimId === nextProps.claimId
);

const DebtorSummary = ({
  claim,
  setUserCountry,
}: DebtorSummaryProps): React.ReactElement => {
  const record = useRecordContext<ExtendedRecord>();
  const amountOfClaims = record?.claimsAsDebtorIds?.length;
  const positionInArray = record?.claimsAsDebtorIds?.indexOf(claim?.id);
  const { hasLawyers, hasInsolvencyAdministrators } = useLawyerStatus(
    claim?.id || ""
  );

  const handleCountryChange = useCallback(
    (country: string) => {
      if (typeof setUserCountry === "function") {
        setUserCountry(country);
      }
    },
    [setUserCountry]
  );

  const country = useDebtorAddress(record?.address?.id, handleCountryChange);
  const iso2CoutryCode = useMemo(
    () => searchMapByKey(ADDRESS_COUNTRY_SHORT, country || ""),
    [country]
  );

  return (
    <>
      <FunctionField
        label="BusinessType"
        style={{ color: theme.palette.error.main }}
        render={(record: ExtendedRecord) => (
          <span
            title="Business Type"
            style={{
              color:
                record?.businessType === "Consumer" ? "#995200" : "#000099",
              background:
                record?.businessType === "Consumer" ? "#ffc480" : "#d1d1ff",
              padding: 4,
              marginRight: 8,
              fontSize: 10,
              fontWeight: "bold",
              borderRadius: 4,
            }}
          >
            {record?.businessType === "Consumer" ? "B2C" : "B2B"}
          </span>
        )}
      />
      <FunctionField
        label="Name"
        render={(record: ExtendedRecord) => (
          <span
            style={{
              fontWeight: 700,
              color: theme.palette.grey[700],
            }}
          >
            {iso2CoutryCode && iso2CoutryCode !== "de" && (
              <ReactCountryFlag
                countryCode={iso2CoutryCode}
                svg
                style={{ marginRight: 4 }}
              />
            )}
            {record?.businessName || record?.contactName || record?.email}
            {record?.comment && (
              <Tooltip title={record.comment}>
                <InfoRounded
                  style={{
                    height: 18,
                    position: "relative",
                    top: 4,
                    color: theme.palette.primary.main,
                  }}
                />
              </Tooltip>
            )}
          </span>
        )}
      />
      {amountOfClaims && amountOfClaims > 1 && (
        <span
          title="Gesamtanzahl Akten"
          style={{
            background: theme.palette.secondary.main,
            color: "white",
            padding: "5px 10px",
            marginLeft: 10,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <Assignment
            style={{
              height: 12,
              width: 12,
              marginRight: 2,
              position: "relative",
              top: 2,
            }}
          />
          {(positionInArray > 0 ? positionInArray + 1 : "1") +
            " / " +
            amountOfClaims}
        </span>
      )}
      <div
        style={{
          display: "flex",
          position: "absolute",
          right: 0,
          top: -15,
        }}
      >
        {hasLawyers && (
          <span
            title="Anwalt"
            style={{
              background: theme.palette.error.dark,
              color: "white",
              padding: 5,
              marginLeft: 3,
              width: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: "bold",
              borderRadius: 50,
            }}
          >
            <Gavel
              style={{
                height: 10,
                width: 10,
                position: "relative",
              }}
            />
          </span>
        )}
        {hasInsolvencyAdministrators && (
          <span
            title="Insolvenzverwalter"
            style={{
              background: theme.palette.error.dark,
              color: "white",
              padding: 5,
              marginLeft: 3,
              width: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              fontWeight: "bold",
              borderRadius: 50,
            }}
          >
            <MoneyOff
              style={{
                height: 10,
                width: 10,
                position: "relative",
              }}
            />
          </span>
        )}
        {claim?.contactsIds?.length > 0 ? (
          <span
            title="Weitere Kontakte"
            style={{
              background: theme.palette.error.main,
              color: "white",
              padding: 5,
              marginLeft: 3,
              width: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 8,
              fontWeight: "bold",
              borderRadius: 50,
            }}
          >
            <PersonAdd
              style={{
                height: 10,
                width: 10,
                position: "relative",
              }}
            />
            {claim?.contactsIds?.length}
          </span>
        ) : null}
      </div>
    </>
  );
};

const CreditorSummary = (): React.ReactElement => {
  const record = useRecordContext<ExtendedRecord>();
  const { language, integrationFlag } = useCreditorData(
    record?.address?.id,
    record?.integration?.id
  );
  const operatorNames = useOperatorNames(record?.operators);

  const iso2CoutryCode = useMemo(
    () => searchMapByKey(ADDRESS_COUNTRY_SHORT, language || ""),
    [language]
  );

  return (
    <>
      <FunctionField
        label="Business Name"
        render={(record: ExtendedRecord) => (
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                fontWeight: 600,
                verticalAlign: "middle",
                color: theme.palette.success.dark,
              }}
            >
              {iso2CoutryCode && iso2CoutryCode !== "DE" && (
                <ReactCountryFlag
                  countryCode={iso2CoutryCode}
                  svg
                  style={{ marginRight: 4 }}
                />
              )}
              <span
                style={{
                  color: getColorFromString(record?.businessName, 30),
                  background: getColorFromString(record?.businessName, 95),
                  borderRadius: 4,
                  padding: "4px 8px",
                  marginRight: 10,
                  fontSize: 12,
                }}
              >
                {record?.businessName || record?.contactName || record?.email}
              </span>

              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: 0,
                  top: -15,
                }}
              >
                {record?.isVip && (
                  <Tooltip title="VIP">
                    <StarRounded
                      style={{
                        height: 14,
                        color: theme.palette.warning.main,
                      }}
                    />
                  </Tooltip>
                )}
              </div>
              {!!integrationFlag && (
                <Tooltip title={`${integrationFlag} Integration`}>
                  <span
                    style={{
                      fontSize: 10,
                      color: theme.palette.primary.dark,
                    }}
                  >
                    {`[${integrationFlag.slice(0, 3).toUpperCase()}]`}
                  </span>
                </Tooltip>
              )}
              {!!record?.operatorCategory ||
              (!!operatorNames && operatorNames.length) ? (
                <Tooltip
                  title={
                    operatorNames.join(", ") +
                    (!!record?.operatorCategory &&
                      ` (${record?.operatorCategory})`)
                  }
                >
                  <Typography
                    component="span"
                    variant="body2"
                    style={{
                      fontWeight: "600",
                      wordBreak: "break-word",
                      maxWidth: "100%",
                    }}
                  >
                    <span
                      style={{
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: getColorFromString(
                          record?.operatorCategory || record?.businessName,
                          70
                        ),
                        color: getColorFromString(
                          record?.operatorCategory || record?.businessName,
                          30
                        ),
                        padding: "4px 8px",
                        marginLeft: 10,
                        marginRight: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        borderRadius: 4,
                      }}
                    >
                      {(record?.operatorCategory || record?.businessName || "")
                        .slice(0, 4)
                        .toUpperCase()}
                    </span>
                  </Typography>
                </Tooltip>
              ) : null}
              {record?.comment && (
                <Tooltip title={record.comment}>
                  <InfoRounded
                    style={{
                      height: 18,
                      color: theme.palette.primary.main,
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
};
