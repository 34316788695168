import { useState } from "react";
import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

const NameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (!manualMode) {
    let generatedName: string = "";

    if (values.contactName) {
      generatedName = values.contactName;
      if (values.businessName) {
        generatedName = generatedName + ", " + values.businessName;
      }
    } else if (values.businessName) {
      generatedName = values.businessName;
    }
    values.name = generatedName;
  }

  return (
    <TextInput
      hidden
      style={{ display: "none" }}
      label="Name (generated)"
      source="name"
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

export default NameInput;
