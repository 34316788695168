import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Claim } from "../../api/claim/Claim";
import { theme } from "../../theme/theme";
import { getAuthToken } from "../../util/JsonFilter";

export const ExpiringClaims: React.MemoExoticComponent<() => JSX.Element> =
  React.memo(() => {
    const [expiringClaims, setExpiringClaims] = useState([]);

    useEffect(() => {
      const authHeader = {
        headers: {
          Authorization: getAuthToken(),
          "Content-Type": "application/json",
        },
      };

      fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/cron/monitor?includeTimeBarredClaims=true",
        {
          ...authHeader,
        }
      )
        .then((response) => response.json())
        .then((parsedResponse) => {
          if (parsedResponse.length) {
            setExpiringClaims(parsedResponse);
          }
        });
    }, []);

    return (
      <>
        <Card
          variant="outlined"
          style={{
            background:
              expiringClaims && expiringClaims.length > 0
                ? expiringClaims.length > 100
                  ? theme.palette.error.main
                  : theme.palette.warning.main
                : theme.palette.success.main,
          }}
        >
          <CardContent className="flex-center" style={{ minHeight: 35 }}>
            <Typography component={"span"} variant="subtitle2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
                href={`/`}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Verjährungsliste:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {expiringClaims?.length && expiringClaims?.length > 100
                    ? "🔥 "
                    : ""}
                  {expiringClaims?.length ? expiringClaims.length : "0"}
                  {expiringClaims?.length && expiringClaims?.length >= 100
                    ? " 🔥"
                    : ""}
                </span>
              </a>
            </Typography>
          </CardContent>
        </Card>
        {expiringClaims?.length > 0 && (
          <Card>
            <List
              style={{
                maxHeight: 1200,
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {expiringClaims && (
                <React.Fragment>
                  {expiringClaims.map((claim: Claim, key) => {
                    return (
                      <a
                        href={"#/Claim/" + claim.id + "/show"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        <ListItem
                          key={claim.id}
                          id={claim.id}
                          className="list-item-activity"
                        >
                          <div className="col" style={{ width: 200 }}>
                            <span className="date">{`${format(
                              claim.invoiceCreatedAt,
                              "dd.MM.yyyy"
                            )}: `}</span>

                            <span className="reference">
                              {claim?.reference}
                            </span>
                            <span className="company">
                              {claim?.creditor?.businessName}
                            </span>
                          </div>
                          <div className="col" style={{ width: 200 }}>
                            {claim.status}
                          </div>
                          <div className="col" style={{ width: 200 }}>
                            {claim.totalPending}
                          </div>
                        </ListItem>
                        {expiringClaims.length - 1 !== key && <Divider />}
                      </a>
                    );
                  })}
                </React.Fragment>
              )}
            </List>
          </Card>
        )}
      </>
    );
  });
