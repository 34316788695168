import makeStyles from "@material-ui/core/styles/makeStyles";

export const useTabsStyles = makeStyles((theme) => ({
  MuiTabsIndicator: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));

export const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    animation: "$flash 0.5s ease-in-out 1",
  },
  "@keyframes flash": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes iconBounce": {
    "0%, 100%": {
      transform: "scale(1) rotate(0deg)",
    },
    "25%": {
      transform: "scale(1.1) rotate(-10deg)",
    },
    "50%": {
      transform: "scale(1.2) rotate(0deg)",
    },
    "75%": {
      transform: "scale(1.1) rotate(10deg)",
    },
  },
  icon: {
    animation: "$iconBounce 0.5s ease-in-out",
  },
}));
