import { useGetList } from "react-admin";
import { User } from "../../api/user/User";

export const useManagers = () => {
  const {
    data: managers,
    loaded,
    total,
  } = useGetList<User>(
    "User",
    { page: 1, perPage: 50 },
    { field: "contactName", order: "ASC" },
    {
      businessName: {
        equals: "Debtist GmbH",
      },
      manager: {
        id: "clf4crymt0000s601r43i14j7", // m.benedetti PROD
      },
    }
  );

  return {
    managers,
    totalManagers: total,
    managersLoaded: loaded,
  };
};
