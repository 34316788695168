import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

export const useLawyerStatus = (claimId: string | undefined) => {
  const [hasLawyers, setHasLawyers] = useState<boolean>(false);

  const [hasInsolvencyAdministrators, setHasInsolvencyAdministrators] =
    useState<boolean>(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchLawyerStatus = async () => {
      try {
        const { data } = await dataProvider.getOne("Claim", {
          id: claimId,
        });
        data.contacts.forEach(async (contact) => {
          const { data: user } = await dataProvider.getOne("User", {
            id: contact.id,
          });
          if (!user) return;
          if (user?.userType === "Lawyer") {
            setHasLawyers(true);
          }
        });
      } catch (error) {
        console.error("Error fetching lawyer status:", error);
      }
    };

    fetchLawyerStatus();
  }, [claimId, dataProvider]);

  useEffect(() => {
    const fetchInsolvencyAdministrators = async () => {
      const { data } = await dataProvider.getOne("Claim", {
        id: claimId,
      });
      data.contacts.forEach(async (contact) => {
        const { data: user } = await dataProvider.getOne("User", {
          id: contact.id,
        });
        if (!user) return;
        if (user?.userType === "InsolvencyAdministrator") {
          setHasInsolvencyAdministrators(true);
        }
      });
    };

    fetchInsolvencyAdministrators();
  }, [claimId, dataProvider]);

  return {
    hasLawyers,
    setHasLawyers,
    hasInsolvencyAdministrators,
    setHasInsolvencyAdministrators,
  };
};
