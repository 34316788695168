import { Grid, Typography } from "@material-ui/core";
import { TextInput } from "react-admin";

const CommentForm = (props: any) => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <Typography component={"span"} variant="caption">
        Document
      </Typography>
      <TextInput
        label="Document Retrieval Instructions"
        source="documentInstructions"
        multiline
        fullWidth
        minRows={6}
        helperText="Add Loom links for document retrieval instructions"
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Typography component={"span"} variant="caption">
        Objections
      </Typography>
      <TextInput
        label="Standard Debtor Objections"
        source="standardObjections"
        multiline
        fullWidth
        minRows={6}
        helperText="Enter common debtor objections and responses"
      />
    </Grid>
    <Grid item xs={12}>
      <Typography component={"span"} variant="caption">
        Comments
      </Typography>
      <TextInput
        label="Comment"
        multiline
        fullWidth
        minRows={6}
        source="comment"
      />
    </Grid>
    <Grid item xs={12}>
      <Typography component={"span"} variant="caption">
        Custom Clauses
      </Typography>
      <TextInput
        label="Custom Clauses"
        multiline
        fullWidth
        source="customClauses"
      />
    </Grid>
  </Grid>
);

export default CommentForm;
