import { useCallback, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { getCredentials } from "../../auth";

export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef<Socket | null>(null);
  const retryAttemptsRef = useRef(0);
  const connectTimeoutRef = useRef<NodeJS.Timeout>();

  const initializeSocket = useCallback(() => {
    if (socketRef.current?.connected) return;

    const storedToken = getCredentials()?.bearerToken;
    const token = storedToken?.startsWith("Bearer ")
      ? storedToken.slice(7)
      : storedToken;

    const newSocket = io(`${process.env.REACT_APP_SERVER_URL}/claimStatus`, {
      withCredentials: true,
      transports: ["websocket"],
      auth: { token },
      // Add connection optimization options
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      autoConnect: false, // Prevent automatic connection
    });

    // Setup event handlers before connecting
    newSocket.on("connect", () => {
      setIsConnected(true);
      retryAttemptsRef.current = 0;
    });

    newSocket.on("disconnect", () => {
      setIsConnected(false);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      setIsConnected(false);

      // Implement exponential backoff
      if (connectTimeoutRef.current) {
        clearTimeout(connectTimeoutRef.current);
      }

      retryAttemptsRef.current += 1;
      const backoffDelay = Math.min(
        1000 * Math.pow(2, retryAttemptsRef.current),
        10000
      );

      connectTimeoutRef.current = setTimeout(() => {
        if (socketRef.current) {
          socketRef.current.connect();
        }
      }, backoffDelay);
    });

    socketRef.current = newSocket;

    // Start the connection
    newSocket.connect();

    return () => {
      if (connectTimeoutRef.current) {
        clearTimeout(connectTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const cleanup = initializeSocket();
    return cleanup;
  }, [initializeSocket]);

  return {
    socket: socketRef.current,
    isConnected,
    connect: useCallback(() => {
      if (socketRef.current && !socketRef.current.connected) {
        socketRef.current.connect();
      } else if (!socketRef.current) {
        initializeSocket();
      }
    }, [initializeSocket]),
  };
};
