import { Grid, Typography } from "@material-ui/core";
import qs from "qs";
import { useEffect } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";
import { CLAIM_TYPES } from "../../../api/claim/Claim";
import {
  EnumUserBusinessContactRelationType,
  getReadableBusinessContactRelationType,
} from "../../../api/user/EnumUserBusinessContactRelationType";
import { ClaimTitle } from "../../../claim/ClaimTitle";
import useFormValidation from "../../../context/hook/useFormValidation";
import { ROLES_OPTIONS } from "../../../user/RolesOptions";
import { UserTitle } from "../../../user/UserTitle";
import EmailInput from "../input/EmailInput";
import NameInput from "../input/NameInput";
import UsernameInput from "../input/UsernameInput";

interface BasicInfoStepProps {
  isAdditionalContact: boolean;
  isLaywerUser: boolean;
  isInsolvencyAdministrator: boolean;
  isPartner: boolean;
  onValidationChange?: (isValid: boolean) => void;
  isEdit?: boolean;
}

const randomPassword =
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8);

const Roles = (props: any) => {
  const { values } = useFormState();

  if (props.isPartner && !values.roles) {
    values.roles = ["creditor"];
  } else if (!props.isNormalUser && !values.roles) {
    values.roles = ["debtor"];
  }

  return (
    <SelectArrayInput
      style={!props.isNormalUser ? { display: "none" } : {}}
      source="roles"
      choices={ROLES_OPTIONS}
      required
      optionText="label"
      optionValue="value"
    />
  );
};

const BasicInfoForm: React.FC<BasicInfoStepProps> = ({
  isAdditionalContact,
  isLaywerUser,
  isInsolvencyAdministrator,
  isPartner,
  onValidationChange,
  isEdit,
}) => {
  const notify = useNotify();
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();

  const basicInfoValidation = useFormValidation({
    contactName: {
      required: true,
    },
    email: {
      required: true,
    },
    username: {
      required: true,
    },
    password: {
      required: true,
    },
    roles: {
      required: true,
    },
  });

  useEffect(() => {
    if (!onValidationChange) {
      return;
    }
    const hasErrors = basicInfoValidation.hasErrors();
    const errors = basicInfoValidation.getErrors();

    if (
      !values ||
      Object.keys(values).length === 0 ||
      !values.email ||
      !values.roles
    )
      return;

    if (hasErrors) {
      notify("Validation errors:" + JSON.stringify(errors));
    }
    onValidationChange && onValidationChange(!hasErrors);
  }, [values, basicInfoValidation, onValidationChange, notify]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        {parsedLocation.claimId && (
          <div
            style={{
              display: "hidden",
            }}
          >
            <ReferenceArrayInput
              filterToQuery={(searchText) => ({
                reference: { startsWith: searchText },
              })}
              isRequired
              defaultValue={[parsedLocation.claimId]}
              source="claimsAsContact"
              reference="Claim"
              label="Claim"
            >
              <AutocompleteArrayInput
                suggestionLimit={5}
                optionText={ClaimTitle}
              />
            </ReferenceArrayInput>
          </div>
        )}
        <Typography component={"span"} variant="caption">
          Contact Details
        </Typography>
        <TextInput
          fullWidth
          label="Contact Name"
          source="contactName"
          autoFocus
          required
        />
        <TextInput
          fullWidth
          required={isLaywerUser ? true : false}
          label="Business Name"
          source="businessName"
        />
        <EmailInput isEdit={isEdit} />
        <TextInput fullWidth label="Phone" source="phone" />
        {!isAdditionalContact &&
          !isLaywerUser &&
          !isInsolvencyAdministrator && (
            <>
              <DateInput label="Birthday" fullWidth source="birthday" />
              <TextInput label="Image URL" source="imageUrl" />
              <SelectInput
                fullWidth
                source="industry"
                label="Industry"
                choices={[
                  { label: "Banking", value: "Banking" },
                  { label: "Fintech", value: "Fintech" },
                  { label: "Insurance", value: "Insurance" },
                  { label: "Lending", value: "Lending" },
                  {
                    label: "PaymentServiceProvider",
                    value: "PaymentServiceProvider",
                  },
                  { label: "ECommerce", value: "ECommerce" },
                  { label: "Software", value: "Software" },
                  { label: "Telecom", value: "Telecom" },
                  { label: "Healthcare", value: "Healthcare" },
                  { label: "Fitness", value: "Fitness" },
                  { label: "Construction", value: "Construction" },
                  { label: "RealEstate", value: "RealEstate" },
                  { label: "Rental", value: "Rental" },
                  { label: "Government", value: "Government" },
                  { label: "Education", value: "Education" },
                  { label: "Consulting", value: "Consulting" },
                  { label: "Retail", value: "Retail" },
                  { label: "Marketplace", value: "Marketplace" },
                  { label: "Logistics", value: "Logistics" },
                  { label: "Fulfillment", value: "Fulfillment" },
                  { label: "Wholesale", value: "Wholesale" },
                  { label: "Manufacturing", value: "Manufacturing" },
                  { label: "Media", value: "Media" },
                  { label: "Mobility", value: "Mobility" },
                  { label: "Parking", value: "Parking" },
                ]}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
              <SelectInput
                fullWidth
                source="defaultClaimType"
                label="Default Claim Type"
                choices={CLAIM_TYPES.map((claim) => ({
                  label: claim.title + " (" + claim.number + ")",
                  value: claim.key,
                }))}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
            </>
          )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          Username & Password
        </Typography>
        <NameInput fullWidth />
        <UsernameInput fullWidth required isPartner />
        <PasswordInput
          initiallyVisible={!isAdditionalContact}
          fullWidth
          required
          source="password"
          defaultValue={isEdit ? undefined : randomPassword}
        />
        <SelectInput
          source="emailVerificationStatus"
          label="Email Status"
          choices={[
            { label: "VALID", value: "VALID" },
            { label: "INVALID", value: "INVALID" },
            { label: "UNKNOWN", value: "UNKNOWN" },
          ]}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
        <SelectInput
          source="phoneVerificationStatus"
          label="Phone Status"
          choices={[
            { label: "VALID", value: "VALID" },
            { label: "INVALID", value: "INVALID" },
            { label: "UNKNOWN", value: "UNKNOWN" },
          ]}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component={"span"} variant="caption">
          User Roles & Permissions
        </Typography>
        <SelectInput
          source="userType"
          label="User Type"
          choices={[
            { id: "Default", name: "Default" },
            { id: "Lawyer", name: "Lawyer" },
            {
              id: "InsolvencyAdministrator",
              name: "Insolvency Administrator",
            },
            { id: "AdditionalContact", name: "Additional Contact" },
            { id: "Partner", name: "Partner" },
          ]}
          defaultValue={
            isLaywerUser
              ? "Lawyer"
              : isInsolvencyAdministrator
              ? "InsolvencyAdministrator"
              : isPartner
              ? "Partner"
              : isAdditionalContact
              ? "AdditionalContact"
              : "Default"
          }
          required
        />
        <SelectInput
          source="businessType"
          label="Business Type"
          choices={[
            { id: "Business", name: "B2B (Business)" },
            { id: "Consumer", name: "B2C (Consumer)" },
            { id: "Merchant", name: "B2C (Merchant)" },
          ]}
          defaultValue="Business"
        />
        {!isAdditionalContact &&
          !isLaywerUser &&
          !isInsolvencyAdministrator && (
            <>
              <SelectInput
                source="businessContactRelationType"
                label="Business Contact Relation"
                choices={Object.values(EnumUserBusinessContactRelationType).map(
                  (relationType) => ({
                    label: getReadableBusinessContactRelationType(relationType),
                    value: relationType,
                  })
                )}
                optionText="label"
                optionValue="value"
              />
              <ReferenceInput
                perPage={10}
                filterToQuery={(searchText) => ({
                  name: { startsWith: searchText },
                  businessName: { equals: "Debtist GmbH" },
                  roles: { array_contains: "manager" },
                })}
                sort={{ field: "contactName", order: "ASC" }}
                source="manager.id"
                reference="User"
                label="Manager"
              >
                <AutocompleteInput
                  suggestionLimit={10}
                  optionText={UserTitle}
                />
              </ReferenceInput>
            </>
          )}
        <Roles isNormalUser={!isAdditionalContact} isPartner={isPartner} />
      </Grid>
    </Grid>
  );
};

export default BasicInfoForm;
