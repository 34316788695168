import { Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import {
  Button,
  Datagrid,
  ExportButton,
  LoadingIndicator,
  Pagination,
  ReferenceManyField,
  useDataProvider,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
// todo: clean up these imports
import { ActivityCreate } from "../../../../activity/ActivityCreate";
import {
  Activity,
  getReadableActivityType,
} from "../../../../api/activity/Activity";
import { Claim } from "../../../../api/claim/Claim";
import { User } from "../../../../api/user/User";
import ActivityEventItemView, {
  ActivityComment,
} from "../../../ActivityEventItem";
import { ClaimActivityTools } from "../../../ClaimActivityTools";

const ActivityInlineCreate = React.memo(
  (props: {
    activityPlaceholderText?: string;
    setActivityPlaceholderText?: any;
  }) => {
    const record: Claim = useRecordContext();

    if (!record) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        <Typography component={"span"} id="new-activity" variant="caption">
          New Activity
        </Typography>
        <ActivityCreate
          basePath="/Activity"
          resource="Activity"
          options={{
            inline: true,
            claimId: record?.id,
            placeholderComment: props.activityPlaceholderText,
            setPlaceholderComment: props.setActivityPlaceholderText,
          }}
        />
      </div>
    );
  }
);

const ActivityListItems = () => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const ActivityExportButton = () => {
    const { data } = useListContext();

    const activities: Activity[] = Object.values(data) as Activity[];

    const downloadCSV: (filename: string, data: Activity[]) => void = (
      filename: string
    ) => {
      // Logic to convert activities data to CSV format and initiate download
      const csvData: string = activities
        .map(
          (activity) =>
            `${activity.activityDate || ""},${activity.id || ""},${
              getReadableActivityType(activity.activityType) || ""
            },${activity.claimAction || ""},${activity.comment || ""}`
        )
        .join("\n");
      const csvHeader = "Date, ID, Activity Type, Action, Comment";
      const csvWithHeader: string = `${csvHeader}\n${csvData}`;

      const blob = new Blob([csvWithHeader], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    return (
      <ExportButton
        style={{
          margin: "1rem",
          position: "absolute",
          left: 30,
          bottom: 30,
          maxWidth: 200,
          zIndex: 99,
        }}
        onClick={() => downloadCSV(`activities.csv`, activities)}
      />
    );
  };

  return (
    <>
      <Datagrid
        optimized
        hasBulkActions
        selectedIds={listContext.selectedIds}
        rowStyle={(record) => {
          if (
            record?.aiWorker ||
            (record?.activityType?.startsWith("Recommended") &&
              !record?.isPlanned)
          ) {
            return {
              display: "none",
            };
          }

          if (record?.isPlanned) {
            return {
              backgroundColor: "#ebfaff",
            };
          }
        }}
        isRowSelectable={(record) => record?.isPlanned}
        rowClick="edit"
        style={{
          overflow: "hidden",
          overflowX: "auto",
        }}
      >
        <ActivityEventItemView />
        <ActivityComment />
      </Datagrid>

      {!!listContext.selectedIds && !!listContext.selectedIds.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={async () => {
            for (const selectedId of listContext.selectedIds) {
              await dataProvider.delete("Activity", {
                id: selectedId,
              });
            }
            listContext.onUnselectItems();
            refresh(true);
          }}
          label="Delete"
        />
      )}
      <ActivityExportButton />
    </>
  );
};

interface ActivitiesTabProps {
  showActivityAdd: boolean;
  setShowActivityAdd: React.Dispatch<React.SetStateAction<boolean>>;
  activityPlaceholderText: string;
  setActivityPlaceholderText: (value: string) => void;
  creditor: User;
  debtor: User;
}

export const ActivitiesTab: React.FC<ActivitiesTabProps> = React.memo(
  ({
    showActivityAdd,
    setShowActivityAdd,
    activityPlaceholderText,
    setActivityPlaceholderText,
    creditor,
    debtor,
  }) => {
    const onCreateButtonClick = useCallback(
      (placeholderText?: string) => {
        if (placeholderText !== undefined) {
          setActivityPlaceholderText(placeholderText);
        }
        setShowActivityAdd((prev) => !prev);
      },
      [setActivityPlaceholderText, setShowActivityAdd]
    );

    return (
      <>
        <ClaimActivityTools
          showActivityAdd={showActivityAdd}
          onCreateButtonClick={onCreateButtonClick}
          creditor={creditor}
          debtor={debtor}
        />
        <div
          style={
            !showActivityAdd
              ? {
                  display: "none",
                }
              : undefined
          }
        >
          <ActivityInlineCreate
            activityPlaceholderText={activityPlaceholderText}
            setActivityPlaceholderText={setActivityPlaceholderText}
          />
        </div>
        <ReferenceManyField
          reference="Activity"
          target="ClaimId"
          label="Activities"
          fullWidth
          perPage={25}
          pagination={<Pagination />}
          sort={{ field: "activityDate", order: "DESC" }}
          sortBy="activityDate"
          sortByOrder="DESC"
          sortable={false}
        >
          <ActivityListItems />
        </ReferenceManyField>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.showActivityAdd === nextProps.showActivityAdd &&
    prevProps.activityPlaceholderText === nextProps.activityPlaceholderText &&
    prevProps.creditor === nextProps.creditor &&
    prevProps.debtor === nextProps.debtor
);
